import {
  Box,
  Divider,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import CustomText from "../../components/text/CustomText";
import MobileIcon from "../../svgs/MobileIcon";
import Hero1 from "../../assets/images/digital-signage/hero-1.png";
import Hero2 from "../../assets/images/digital-signage/hero-2.png";
import Hero3 from "../../assets/images/digital-signage/hero-3.png";
import Hero4 from "../../assets/images/digital-signage/hero-4.png";
import Hero5 from "../../assets/images/digital-signage/hero-5.png";
import Hero6 from "../../assets/images/digital-signage/hero-6.png";
import CustomButton from "../../components/buttons/CustomButton";
import { IconPlus, IconX } from "@tabler/icons-react";
import AdScreens from "./AdScreens";
import InfoScreens from "./InfoScreens";
import EventScreens from "./EventScreens";
import UploadIcon from "../../svgs/UploadIcon";
import FormInput from "../../components/form/FormInput";
import IconChevDown from "../../svgs/IconChevDown";
import DigitalSignageChart from "../../components/charts/DigitalSignageChart";

function DigitalSignage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div>
      <Flex gap="24px" mb="24px" align="center">
        <CustomCard minH="342px">
          <Flex flexDir="column" gap="24px" justify="space-between">
            <Flex gap="16px" align="center">
              <Flex
                align="center"
                justify="center"
                h="40px"
                w="40px"
                bg={"yess.secondary_light_gray"}
                borderRadius="8px"
              >
                <MobileIcon />
              </Flex>
              <CustomText
                text="Total Digital Signage Screens"
                color="yess.base_black"
              />
            </Flex>{" "}
            <Flex justify="space-between" align="center">
              <DigitalSignageChart />
              <Flex flexDir="column" gap="24px">
                <Flex gap="8px" flexDir="column">
                  <CustomText text="Ads" size="14px" color="yess.neutral500" />
                  <Flex gap="6px" align="center">
                    <Box
                      bg="yess.secondary_gold"
                      height="12px"
                      width="12px"
                      mt="3px"
                    />
                    <CustomText
                      text="96"
                      size="16px"
                      color="yess.neutral1000"
                    />
                  </Flex>
                </Flex>
                <Flex gap="8px" flexDir="column">
                  <CustomText text="Info" size="14px" color="yess.neutral500" />
                  <Flex gap="6px" align="center">
                    <Box bg="#387DF5" height="12px" width="12px" mt="3px" />
                    <CustomText
                      text="96"
                      size="16px"
                      color="yess.neutral1000"
                    />
                  </Flex>
                </Flex>
                <Flex gap="8px" flexDir="column">
                  <CustomText
                    text="Event"
                    size="14px"
                    color="yess.neutral500"
                  />
                  <Flex gap="6px" align="center">
                    <Box bg="#2EC19E" height="12px" width="12px" mt="3px" />
                    <CustomText
                      text="96"
                      size="16px"
                      color="yess.neutral1000"
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </CustomCard>
        <Flex flexDir="column" gap="16px">
          <Flex gap="16px">
            <Image src={Hero1} alt="hero1" maxW="324px" />
            <Image src={Hero2} alt="hero2" maxW="244px" />
            <Image src={Hero3} alt="hero3" maxW="234px" />
          </Flex>
          <Flex gap="16px">
            <Image src={Hero4} alt="hero4" maxW="158px" />
            <Image src={Hero5} alt="hero5" maxW="151px" />
            <Image src={Hero6} alt="hero6" maxW="493px" />
          </Flex>
        </Flex>
      </Flex>
      <CustomCard>
        <Flex justify="space-between" align="center">
          <CustomText
            text="Screens Distributions"
            size="18px"
            color="yess.base_black"
          />
          <CustomButton
            btnTitle="Add New Ad"
            size="12px"
            weight="400"
            color="#fff"
            h="48px"
            bg="yess.secondary_gold"
            radius="12px"
            border="1px solid"
            borderColor="yess.secondary_gold"
            maxW="124px"
            leftIco={<IconPlus color="#fff" size="14px" />}
            onClick={onOpen}
          />
        </Flex>
        <AdScreens />
        <Divider my="12px" bg="#f2f2f2" />
        <InfoScreens />
        <Divider my="12px" bg="#f2f2f2" />
        <EventScreens />
      </CustomCard>
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent maxW="600px">
          <Flex align="center" justify="space-between" p="24px" w="100%">
            <CustomText
              text="Add New Ad"
              size="20px"
              color="yess.base_black"
              mt="-2px"
            />
            <IconX
              size="20px"
              color="#000"
              onClick={() => {
                onClose();
              }}
            />
          </Flex>
          <Divider bg="#CFCECE" />
          <Flex p="24px" flexDir="column" gap="24px">
            <Flex
              flexDir="column"
              gap="16px"
              align="center"
              p="32px 24px"
              borderRadius="16px"
              border="2px dashed"
              borderColor="yess.neutral500"
              bg="#F2F7FF"
            >
              <UploadIcon />
              <CustomText
                text="Drag or Drop Ad Files"
                color="yess.base_black"
              />
              <CustomButton
                btnTitle="Browse"
                color="#fff"
                bg="yess.secondary_gold"
                maxW="111px"
                height="36px"
                size="14px"
                weight="400"
              />
            </Flex>
            <Flex flexDir="column" gap="12px">
              <CustomText text="Choose Screen" size="14px" />
              <FormInput h="48px" ph="Ad Screen" rightIco={<IconChevDown />} />
            </Flex>
            <Flex flexDir="column" gap="12px">
              <CustomText text="Description" size="14px" />
              <Textarea
                placeholder="Type here..."
                borderRadius="12px"
                bg="yess.secondary_light_gray"
                fontSize="12px"
                px="24px"
                py="16px"
                fontWeight="400"
                color="yess.base_black"
                _placeholder={{
                  color: "yess.neutral600",
                }}
                border="none"
                _focus={{
                  border: "none",
                }}
                _focusVisible={{
                  border: "none",
                }}
                _hover={{
                  border: "none",
                }}
                className="aeonik-regular"
              />
            </Flex>
            <Flex
              pt="16px"
              borderTop="1px solid"
              borderColor="yess.neutral100"
              justify="end"
            >
              <CustomButton
                btnTitle="Upload"
                maxW="200px"
                bg="yess.secondary_gold"
                onClick={() => onClose()}
              />
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default DigitalSignage;
