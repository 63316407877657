import { Box, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import ArabiaShow from "../../svgs/ArabiaShow";
import DashboardIcon from "../../svgs/DashboardIcon";
import ExhibitorsIcon from "../../svgs/ExhibitorsIcon";
import OrganizationsIcon from "../../svgs/OrganizationsIcon";
import VisitorsIcon from "../../svgs/VisitorsIcon";
import BadgeIcon from "../../svgs/BadgeIcon";
import BoothIcon from "../../svgs/BoothIcon";
import DigitalSignageIcon from "../../svgs/DigitalSignageIcon";
import AnalyticsIcon from "../../svgs/AnalyticsIcon";
import CustomText from "../text/CustomText";
import { useLocation, useNavigate } from "react-router-dom";
import PromoCodeIcon from "../../svgs/PromoCodeIcon";
import IconMofa from "../../svgs/IconMofa";
import CrowdIcon from "../../svgs/CrowdIcon";
import SmartGatesIcon from "../../svgs/SmartGatesIcon";
import CarIcon from "../../svgs/CarIcon";
import GovernmentIcon from "../../svgs/GovernmentIcon";
import QRIcon from "../../svgs/QRIcon";
import MapIcon from "../../svgs/MapIcon";
import UserIcon from "../../svgs/UserIcon";
import FinanceIcon from "../../svgs/FinanceIcon";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

const MAIN_MENU = [
  {
    title: "Dashboard",
    path: "/",
    isEnabled: true,
    icon: <DashboardIcon />,
    activeIcon: <DashboardIcon color="#D79A2B" />,
  },
  {
    title: "Exhibitors",
    path: "/exhibitors",
    path2: "/exhibitors/create-exhibitor",
    isEnabled: true,
    icon: <ExhibitorsIcon />,
    activeIcon: <ExhibitorsIcon color="#D79A2B" />,
  },
  {
    title: "Organizations",
    path: "/organizations",
    isEnabled: true,
    icon: <OrganizationsIcon />,
    activeIcon: <OrganizationsIcon color="#D79A2B" />,
  },
  {
    title: "Visitors",
    path: "/visitors",
    isEnabled: true,
    icon: <VisitorsIcon />,
    activeIcon: <VisitorsIcon color="#D79A2B" />,
  },
  {
    title: "Badge",
    path: "/badges",
    isEnabled: true,
    icon: <BadgeIcon />,
    activeIcon: <BadgeIcon color="#D79A2B" />,
  },
  {
    title: "Booth",
    path: "/booths",
    isEnabled: true,
    icon: <BoothIcon />,
    activeIcon: <BoothIcon color="#D79A2B" />,
  },
  {
    title: "Digital Signage",
    path: "/digital-signage",
    isEnabled: true,
    icon: <DigitalSignageIcon />,
    activeIcon: <DigitalSignageIcon color="#D79A2B" />,
  },
  {
    title: "Analytics",
    path: "/analytics",
    isEnabled: true,
    icon: <AnalyticsIcon />,
    activeIcon: <AnalyticsIcon color="#D79A2B" />,
  },
  {
    title: "Promo Code",
    path: "/promo-code",
    isEnabled: false,
    icon: <PromoCodeIcon />,
    activeIcon: <PromoCodeIcon />,
  },
  {
    title: "MOFA",
    path: "/mofa",
    isEnabled: false,
    icon: <IconMofa />,
    activeIcon: <IconMofa />,
  },
];

const SERVICES = [
  {
    title: "Crowd Management",
    path: "/",
    icon: <CrowdIcon />,
  },
  {
    title: "Smart Gates",
    path: "/",
    icon: <SmartGatesIcon />,
  },
  {
    title: "Parking Management",
    path: "/",
    icon: <CarIcon />,
  },
  {
    title: "Government Services",
    path: "/",
    icon: <GovernmentIcon />,
  },
  {
    title: "QR Scanning",
    path: "/",
    icon: <QRIcon />,
  },
  {
    title: "3D WayFinding",
    path: "/",
    icon: <MapIcon />,
  },
];

const OTHERS = [
  {
    title: "Admin Management",
    path: "/",
    icon: <UserIcon />,
  },
  {
    title: "Finance",
    path: "/",
    icon: <FinanceIcon />,
  },
];

function Sidebar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isServicesOpen, setIsServicesOpen] = useState(true);
  const [isOthersOpen, setIsOthersOpen] = useState(true);

  return (
    <Box
      minW="256px"
      borderRight="1px solid"
      borderColor="yess.border"
      bg="#fff"
      minH="100vh"
      className="sidebar"
    >
      <Flex
        borderBottom="1px solid"
        borderColor="yess.border"
        p="20px 24px"
        h="88px"
      >
        <ArabiaShow />
      </Flex>
      <Flex
        flexDir="column"
        gap="12px"
        p="16px"
        maxH="calc(100vh - 88px)"
        overflowY="auto"
      >
        <CustomText
          text="Main Menu"
          size="10px"
          weight="700"
          color="yess.neutral300"
          transform="uppercase"
        />
        <Flex flexDir="column" gap="4px" mt="4px">
          {MAIN_MENU.map((item, index) => (
            <Flex
              key={index}
              align="center"
              p="12px 8px"
              gap="8px"
              borderRadius="8px"
              cursor="pointer"
              pos="relative"
              bg={
                pathname === item.path || pathname === item?.path2
                  ? "#F7F7F7"
                  : "transparent"
              }
              onClick={() => {
                if (item?.isEnabled) navigate(item.path);
              }}
            >
              {pathname === item.path || pathname === item?.path2
                ? item.activeIcon
                : item.icon}
              <CustomText
                text={item.title}
                size="14px"
                color={
                  pathname === item?.path || pathname === item?.path2
                    ? "yess.secondary_gold"
                    : item?.isEnabled
                    ? "yess.neutral800"
                    : "yess.neutral300"
                }
                cursor
              />
              {(pathname === item.path || pathname === item?.path2) && (
                <Box
                  pos="absolute"
                  left="0"
                  ml="-16px"
                  width="6px"
                  height="40px"
                  bg="yess.secondary_gold"
                  borderRadius="0px 10px 10px 0px"
                />
              )}
            </Flex>
          ))}
        </Flex>
        <Flex
          align="center"
          justify="space-between"
          cursor="pointer"
          onClick={() => {
            setIsServicesOpen(!isServicesOpen);
          }}
        >
          <CustomText
            text="Services"
            size="10px"
            weight="700"
            color="yess.neutral300"
            transform="uppercase"
          />
          {isServicesOpen ? (
            <IconChevronUp size="16px" color="#908B8B" />
          ) : (
            <IconChevronDown size="16px" color="#908B8B" />
          )}
        </Flex>
        {/* <Collapse in={isServicesOpen} animateOpacity> */}
        <Flex
          flexDir="column"
          gap="4px"
          mt="4px"
          display={isServicesOpen ? "flex" : "none"}
        >
          {SERVICES.map((item, index) => (
            <Flex
              key={index}
              align="center"
              p="12px 8px"
              gap="8px"
              borderRadius="8px"
              cursor="pointer"
              pos="relative"
            >
              {item.icon}
              <CustomText
                text={item.title}
                size="14px"
                color={"yess.neutral300"}
                cursor
              />
            </Flex>
          ))}
        </Flex>
        {/* </Collapse> */}
        <Flex
          align="center"
          justify="space-between"
          cursor="pointer"
          onClick={() => {
            setIsOthersOpen(!isOthersOpen);
          }}
        >
          <CustomText
            text="Others"
            size="10px"
            weight="700"
            color="yess.neutral300"
            transform="uppercase"
          />
          {isOthersOpen ? (
            <IconChevronUp size="16px" color="#908B8B" />
          ) : (
            <IconChevronDown size="16px" color="#908B8B" />
          )}
        </Flex>
        {/* <Collapse in={isOthersOpen} animateOpacity> */}
        <Flex
          flexDir="column"
          gap="4px"
          mt="4px"
          display={isOthersOpen ? "flex" : "none"}
        >
          {OTHERS.map((item, index) => (
            <Flex
              key={index}
              align="center"
              p="12px 8px"
              gap="8px"
              borderRadius="8px"
              cursor="pointer"
              pos="relative"
            >
              {item.icon}
              <CustomText
                text={item.title}
                size="14px"
                color={"yess.neutral300"}
                cursor
              />
            </Flex>
          ))}
        </Flex>
        {/* </Collapse> */}
      </Flex>
    </Box>
  );
}

export default Sidebar;
