import React from "react";

function TwoUserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.16055 10.8701C9.06055 10.8601 8.94055 10.8601 8.83055 10.8701C6.45055 10.7901 4.56055 8.84006 4.56055 6.44006C4.56055 3.99006 6.54055 2.00006 9.00055 2.00006C11.4505 2.00006 13.4405 3.99006 13.4405 6.44006C13.4305 8.84006 11.5405 10.7901 9.16055 10.8701Z"
        stroke="#0B0A0A"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.4103 3.99994C18.3503 3.99994 19.9103 5.56994 19.9103 7.49994C19.9103 9.38994 18.4103 10.9299 16.5403 10.9999C16.4603 10.9899 16.3703 10.9899 16.2803 10.9999"
        stroke="#0B0A0A"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.15973 14.5599C1.73973 16.1799 1.73973 18.8199 4.15973 20.4299C6.90973 22.2699 11.4197 22.2699 14.1697 20.4299C16.5897 18.8099 16.5897 16.1699 14.1697 14.5599C11.4297 12.7299 6.91973 12.7299 4.15973 14.5599Z"
        stroke="#0B0A0A"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3398 20.0001C19.0598 19.8501 19.7398 19.5601 20.2998 19.1301C21.8598 17.9601 21.8598 16.0301 20.2998 14.8601C19.7498 14.4401 19.0798 14.1601 18.3698 14.0001"
        stroke="#0B0A0A"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default TwoUserIcon;
