import React from "react";

function BoothShopIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
    >
      <path
        d="M4.15789 7.94737V13.6316M23.1053 7.94737V13.6316M1 1H26.2632V6.05263L25.3802 6.58316C24.6931 6.99542 23.9069 7.2132 23.1056 7.2132C22.3043 7.2132 21.5181 6.99542 20.8309 6.58316L19.9474 6.05263L19.0644 6.58316C18.3773 6.99542 17.5911 7.2132 16.7898 7.2132C15.9885 7.2132 15.2023 6.99542 14.5152 6.58316L13.6316 6.05263L12.7486 6.58316C12.0615 6.99542 11.2753 7.2132 10.474 7.2132C9.6727 7.2132 8.88647 6.99542 8.19937 6.58316L7.31579 6.05263L6.43221 6.58316C5.74518 6.9953 4.95907 7.21301 4.15789 7.21301C3.35672 7.21301 2.57061 6.9953 1.88358 6.58316L1 6.05263V1ZM2.26316 13.6316H25V25H2.26316V13.6316Z"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default BoothShopIcon;
