import { Divider, Flex } from "@chakra-ui/react";
import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import IconBadge from "../../svgs/IconBadge";
import CustomText from "../../components/text/CustomText";

function Stats() {
  return (
    <Flex gap="24px" align="center">
      <CustomCard>
        <Flex flexDir="column" justify="space-between" minH="152px">
          <Flex gap="16px" align="center">
            <Flex
              align="center"
              justify="center"
              h="40px"
              w="40px"
              bg={"yess.secondary_light_gray"}
              borderRadius="8px"
            >
              <IconBadge />
            </Flex>
            <CustomText text="Total Organizations" color="yess.base_black" />
          </Flex>
          <Flex gap="10px" align="center">
            <CustomText text="320" size="32px" color="yess.base_black" />
            <Badge text="+32.07 %" />
          </Flex>
          <CustomText
            text={
              <>
                <span
                  style={{
                    color: "#2EC19E",
                  }}
                >
                  2.07%
                </span>
                <span> than last month</span>
              </>
            }
            size="14px"
            color="yess.neutral700"
          />
        </Flex>
      </CustomCard>
      <CustomCard>
        <Flex flexDir="column" justify="space-between" minH="152px">
          <Flex gap="16px" align="center">
            <CustomText text="Media" wrap="nowrap" color="yess.base_black" />
            <Divider bg="#F2F2F2" />
          </Flex>
          <Flex gap="10px" align="center">
            <CustomText text="70" size="32px" color="yess.base_black" />
            <Badge text="+32.07 %" />
          </Flex>
          <CustomText
            text={
              <>
                <span
                  style={{
                    color: "#2EC19E",
                  }}
                >
                  2.07%
                </span>
                <span> than last month</span>
              </>
            }
            size="14px"
            color="yess.neutral700"
          />
        </Flex>
      </CustomCard>
      <CustomCard>
        <Flex flexDir="column" justify="space-between" minH="152px">
          <Flex gap="16px" align="center">
            <CustomText
              text="Contractor"
              wrap="nowrap"
              color="yess.base_black"
            />
            <Divider bg="#F2F2F2" />
          </Flex>
          <Flex gap="10px" align="center">
            <CustomText text="58" size="32px" color="yess.base_black" />
            <Badge text="-2.30 %" bg="#ED3D4E" color="#fff" />
          </Flex>
          <CustomText
            text={
              <>
                <span
                  style={{
                    color: "#ED3D4E",
                  }}
                >
                  -16.07%
                </span>
                <span> than last month</span>
              </>
            }
            size="14px"
            color="yess.neutral700"
          />
        </Flex>
      </CustomCard>
      <CustomCard>
        <Flex flexDir="column" justify="space-between" minH="152px">
          <Flex gap="16px" align="center">
            <CustomText
              text="University"
              wrap="nowrap"
              color="yess.base_black"
            />
            <Divider bg="#F2F2F2" />
          </Flex>
          <Flex gap="10px" align="center">
            <CustomText text="62" size="32px" color="yess.base_black" />
            <Badge text="+32.07 %" />
          </Flex>
          <CustomText
            text={
              <>
                <span
                  style={{
                    color: "#2EC19E",
                  }}
                >
                  2.07%
                </span>
                <span> than last month</span>
              </>
            }
            size="14px"
            color="yess.neutral700"
          />
        </Flex>
      </CustomCard>
      <CustomCard>
        <Flex flexDir="column" justify="space-between" minH="152px">
          <Flex gap="16px" align="center">
            <CustomText text="Defence" wrap="nowrap" color="yess.base_black" />
            <Divider bg="#F2F2F2" />
          </Flex>
          <Flex gap="10px" align="center">
            <CustomText text="130" size="32px" color="yess.base_black" />
            <Badge text="+32.07 %" />
          </Flex>
          <CustomText
            text={
              <>
                <span
                  style={{
                    color: "#2EC19E",
                  }}
                >
                  2.07%
                </span>
                <span> than last month</span>
              </>
            }
            size="14px"
            color="yess.neutral700"
          />
        </Flex>
      </CustomCard>
    </Flex>
  );
}

const Badge = ({ bg, color, text }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      px="12px"
      py="4px"
      borderRadius="99px"
      bg={bg || "#D5FFF5"}
    >
      <CustomText text={text} size="12px" color={color || "#2EC19E"} />
    </Flex>
  );
};

export default Stats;
