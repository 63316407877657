import React from "react";

function FinanceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.6665 3.99967V5.61301C14.6665 6.66634 13.9998 7.33301 12.9465 7.33301H10.6665V2.67301C10.6665 1.93301 11.2732 1.33301 12.0132 1.33301C12.7399 1.33967 13.4065 1.63301 13.8865 2.11301C14.3665 2.59967 14.6665 3.26634 14.6665 3.99967Z"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.3335 4.66634V13.9997C1.3335 14.553 1.96015 14.8663 2.40015 14.533L3.54016 13.6797C3.80683 13.4797 4.18017 13.5063 4.42017 13.7463L5.52681 14.8597C5.78681 15.1197 6.21351 15.1197 6.47351 14.8597L7.59351 13.7397C7.82684 13.5063 8.20016 13.4797 8.46016 13.6797L9.60018 14.533C10.0402 14.8597 10.6668 14.5463 10.6668 13.9997V2.66634C10.6668 1.93301 11.2668 1.33301 12.0002 1.33301H4.66683H4.00016C2.00016 1.33301 1.3335 2.52634 1.3335 3.99967V4.66634Z"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 6H8"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.5 8.66699H7.5"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default FinanceIcon;
