import {
  Avatar,
  Checkbox,
  Divider,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import SaudiMIco from "../../assets/images/saudi-military.svg";
import USMIco from "../../assets/images/us-military.svg";
import UKMIco from "../../assets/images/uk-military.svg";
import GermanyMIco from "../../assets/images/germany-military.svg";
import CanadaMIco from "../../assets/images/canada-military.svg";
import CustomText from "../../components/text/CustomText";
import EyeIcon from "../../svgs/EyeIcon";
import MailIcon from "../../svgs/MailIcon";
import EditIcon from "../../svgs/EditIcon";
import ArrowLeft from "../../svgs/ArrowLeft";
import ArrowRight from "../../svgs/ArrowRight";
import JohnDoe from "../../assets/images/john-doe.png";
import { IconX } from "@tabler/icons-react";
import IconJpg from "../../svgs/IconJpg";
import CustomButton from "../../components/buttons/CustomButton";
import { useNavigate } from "react-router-dom";

function VisitorTable() {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex mt="16px" gap="20px" flexDir="column">
        <TableContainer overflowY="auto">
          <Table>
            <Thead>
              <Tr>
                <Th
                  px="20px"
                  borderBottom="1px solid #CFCECE"
                  bg="#F2F2F2"
                  borderTopLeftRadius="12px"
                >
                  <CustomText
                    text="Personal Info"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Company Info"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Badge No"
                    size="12px"
                    color="yess.neutral1000"
                    align="center"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Badge Type"
                    size="12px"
                    color="yess.neutral1000"
                    align="center"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Country"
                    size="12px"
                    color="yess.neutral1000"
                    align="center"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Type"
                    size="12px"
                    color="yess.neutral1000"
                    align="center"
                  />
                </Th>
                <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                  <CustomText
                    text="Vetting Status"
                    size="12px"
                    align="center"
                    color="yess.neutral1000"
                  />
                </Th>

                <Th
                  px="20px"
                  borderBottom="1px solid #CFCECE"
                  bg="#F2F2F2"
                  borderTopRightRadius="12px"
                >
                  <CustomText
                    text="Action"
                    size="12px"
                    color="yess.neutral1000"
                  />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {VISITORS?.map((exhi: any, idx: number) => (
                <Tr key={idx}>
                  <Td borderLeft="1px solid #F2F2F2" p="16px 10px">
                    <Flex gap="10px" py="10px" align="center">
                      <Avatar boxSize="32px" />
                      <Flex flexDir="column" gap="4px">
                        <Flex align="center" gap="4px">
                          <CustomText
                            text={exhi?.personalInfo?.name}
                            color="yess.neutral1000"
                            size="14px"
                          />
                          <CustomText
                            text={`(${exhi?.personalInfo?.position})`}
                            color="yess.neutral500"
                            size="10px"
                          />
                        </Flex>
                        <CustomText
                          text={exhi?.personalInfo?.email}
                          color="yess.neutral500"
                          size="10px"
                        />
                      </Flex>
                    </Flex>
                  </Td>
                  <Td borderLeft="1px solid #F2F2F2" p="16px 10px">
                    <Flex flexDir="column" gap="10px">
                      <Flex gap="10px" align="center">
                        <Image
                          src={exhi.companyInfo?.icon}
                          alt={exhi?.companyInfo?.name}
                        />
                        <Flex flexDir="column" gap="4px">
                          <CustomText
                            text={exhi?.companyInfo?.name}
                            color="yess.neutral1000"
                            size="14px"
                          />
                          <CustomText
                            text={exhi?.companyInfo?.country}
                            color="yess.neutral500"
                            size="10px"
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Td>
                  <Td
                    borderLeft="1px solid #f2f2f2"
                    p="16px 10px"
                    verticalAlign="center"
                  >
                    <CustomText
                      text={exhi?.badgeNo}
                      color="yess.neutral800"
                      size="12px"
                      align="center"
                    />
                  </Td>
                  <Td
                    borderLeft="1px solid #f2f2f2"
                    p="16px 10px"
                    verticalAlign="center"
                  >
                    <CustomText
                      text={exhi?.badgeType}
                      color="yess.neutral800"
                      size="12px"
                      align="center"
                    />
                  </Td>
                  <Td
                    borderLeft="1px solid #f2f2f2"
                    p="16px 10px"
                    verticalAlign="center"
                  >
                    <CustomText
                      text={exhi?.companyInfo?.country}
                      color="yess.neutral800"
                      size="12px"
                      align="center"
                    />
                  </Td>
                  <Td borderLeft="1px solid #f2f2f2" p="16px 10px">
                    <CustomText
                      text={exhi?.type}
                      size="12px"
                      color="yess.neutral800"
                      align="center"
                    />
                  </Td>
                  <Td borderLeft="1px solid #f2f2f2" p="16px 10px">
                    <Badge status={exhi.status} />
                  </Td>
                  <Td
                    borderLeft="1px solid #f2f2f2"
                    borderRight="1px solid #f2f2f2"
                    p="16px 10px"
                  >
                    <Flex align="center" gap="6px">
                      <ActionButton
                        icon={<EyeIcon />}
                        onClick={() => {
                          navigate("/visitor-details/1");
                        }}
                      />
                      <ActionButton icon={<MailIcon />} />
                      <ActionButton
                        bg="yess.secondary_gold"
                        icon={<EditIcon />}
                        onClick={onOpen}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex align="center" gap="16px" justify="end">
          <CustomText text="1-10 of 52" size="13px" color="yess.neutral300" />
          <Flex align="center" gap="2px">
            <PaginationButton icon={<ArrowLeft />} />
            <PaginationButton text="1" />
            <PaginationButton text="2" bg="yess.secondary_gold" />
            <PaginationButton text="3" />
            <PaginationButton text="4" />
            <PaginationButton text="5" />
            <PaginationButton icon={<ArrowRight />} />
          </Flex>
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent maxW="609px" p="24px" pos="relative">
          <IconX
            size="20px"
            color="#000"
            cursor="pointer"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              marginRight: "20px",
              marginTop: "20px",
            }}
            onClick={onClose}
          />
          <Flex align="center" justify="space-between">
            <Image src={JohnDoe} alt="john" boxSize="134px" />
            <Flex gap="10px" align="center">
              <CustomText text="Real Image" color="yess.neutral500" mt="-5px" />
              <Checkbox size="lg" />
            </Flex>
          </Flex>
          <CustomText text="Personal Info" color="yess.neutral500" mt="16px" />
          <Flex
            mt="8px"
            flexDir="column"
            gap="16px"
            p="16px"
            borderRadius="12px"
            border="1px solid"
            borderColor="yess.secondary_light_gray"
          >
            <Flex align="center" justify="space-between">
              <CustomText text="Name" color="yess.neutral500" />
              <Flex align="center" gap="40px">
                <CustomText text="Tarek Kareem" color="yess.neutral800" />
                <Checkbox size="lg" isChecked />
              </Flex>
            </Flex>
            <Flex align="center" justify="space-between">
              <CustomText text="Nationality" color="yess.neutral500" />
              <Flex align="center" gap="40px">
                <CustomText text="Saudi Arabia" color="yess.neutral800" />
                <Checkbox size="lg" />
              </Flex>
            </Flex>
            <Flex align="center" justify="space-between">
              <CustomText text="DOB" color="yess.neutral500" />
              <Flex align="center" gap="40px">
                <CustomText text="02 Oct, 1998" color="yess.neutral800" />
                <Checkbox size="lg" />
              </Flex>
            </Flex>
            <Flex align="center" justify="space-between">
              <CustomText text="Address" color="yess.neutral500" />
              <Flex align="center" gap="40px">
                <CustomText text="Mecca Saudi Arabia" color="yess.neutral800" />
                <Checkbox size="lg" />
              </Flex>
            </Flex>
          </Flex>
          <Divider my="12px" bg="#F2F2F2" />
          <CustomText text="Uploaded Docs" color="yess.neutral500" />

          <Flex
            mt="8px"
            flexDir="column"
            gap="16px"
            p="16px"
            borderRadius="12px"
            border="1px solid"
            borderColor="yess.secondary_light_gray"
          >
            <Flex align="center" gap="24px">
              <Flex
                px="16px"
                py="8px"
                borderRadius="16px"
                bg="rgba(242, 242, 242, 0.50)"
                align="center"
                justify="space-between"
                h="52px"
                w="100%"
              >
                <Flex gap="12px" align="center">
                  <IconJpg />
                  <Flex flexDir="column" gap="6px">
                    <CustomText
                      text="Passsport.jpg"
                      size="14px"
                      weight="700"
                      color="yess.neutral700"
                    />
                    <CustomText
                      text="12 Kbs"
                      size="12px"
                      color="yess.neutral500"
                    />
                  </Flex>
                </Flex>
                <CustomButton
                  btnTitle="View"
                  color="#387DF5"
                  bg="#EDF3FF"
                  size="14px"
                  weight="400"
                  borderColor="#387DF5"
                  border="1px solid"
                  maxW="79px"
                  height="32px"
                />
              </Flex>
              <Checkbox size="lg" isChecked />
            </Flex>
            <Flex align="center" gap="24px">
              <Flex
                px="16px"
                py="8px"
                borderRadius="16px"
                bg="rgba(242, 242, 242, 0.50)"
                align="center"
                justify="space-between"
                h="52px"
                w="100%"
              >
                <Flex gap="12px" align="center">
                  <IconJpg />
                  <Flex flexDir="column" gap="6px">
                    <CustomText
                      text="Aqama_front.jpg"
                      size="14px"
                      weight="700"
                      color="yess.neutral700"
                    />
                    <CustomText
                      text="12 Kbs"
                      size="12px"
                      color="yess.neutral500"
                    />
                  </Flex>
                </Flex>
                <CustomButton
                  btnTitle="View"
                  color="#387DF5"
                  bg="#EDF3FF"
                  size="14px"
                  weight="400"
                  borderColor="#387DF5"
                  border="1px solid"
                  maxW="79px"
                  height="32px"
                />
              </Flex>
              <Checkbox size="lg" />
            </Flex>
            <Flex align="center" gap="24px">
              <Flex
                px="16px"
                py="8px"
                borderRadius="16px"
                bg="rgba(242, 242, 242, 0.50)"
                align="center"
                justify="space-between"
                h="52px"
                w="100%"
              >
                <Flex gap="12px" align="center">
                  <IconJpg />
                  <Flex flexDir="column" gap="6px">
                    <CustomText
                      text="Aqama_back.jpg"
                      size="14px"
                      weight="700"
                      color="yess.neutral700"
                    />
                    <CustomText
                      text="12 Kbs"
                      size="12px"
                      color="yess.neutral500"
                    />
                  </Flex>
                </Flex>
                <CustomButton
                  btnTitle="View"
                  color="#387DF5"
                  bg="#EDF3FF"
                  size="14px"
                  weight="400"
                  borderColor="#387DF5"
                  border="1px solid"
                  maxW="79px"
                  height="32px"
                />
              </Flex>
              <Checkbox size="lg" />
            </Flex>
          </Flex>
          <Divider my="12px" bg="#F2F2F2" />
          <CustomText text="Your Remarks (Optional)" color="yess.neutral500" />
          <Textarea mt="8px" mb="16px" />
          <CustomButton
            btnTitle="Add Remarks"
            maxW="148px"
            bg="yess.secondary_gold"
            size="14px"
            weight="400"
            color="#fff"
            radius="12px"
            onClick={onClose}
          />
        </ModalContent>
      </Modal>
    </>
  );
}

const ActionButton = ({ icon, bg, onClick }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="32px"
      w="32px"
      bg={bg || "yess.secondary_light_gray"}
      borderRadius="8px"
      cursor="pointer"
      onClick={onClick}
    >
      {icon}
    </Flex>
  );
};

const Badge = ({ status }: any) => {
  const getBgColor = () => {
    switch (status) {
      case "Approved":
        return "#F1FFFC";
      case "Rejected":
        return "#FFE9EB";
      case "Pending":
        return "#FFF9F1";
      case "Info Required":
        return "#F9F9F9";
      default:
        return "yess.secondary_light_gray";
    }
  };

  const getBorderColor = () => {
    switch (status) {
      case "Approved":
        return "#2EC19E";
      case "Rejected":
        return "#ED3D4E";
      case "Pending":
        return "#EDA145";
      case "Info Required":
        return "#4E4949";
      default:
        return "yess.base_black";
    }
  };

  return (
    <Flex
      align="center"
      justify="center"
      w="104px"
      h="30px"
      borderRadius="99px"
      border="1px solid"
      borderColor={getBorderColor()}
      bg={getBgColor()}
      mx="auto"
    >
      <CustomText text={status} size="12px" color={getBorderColor()} />
    </Flex>
  );
};

const PaginationButton = ({ text, bg, icon }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="30px"
      w="30px"
      bg={bg || "#fff"}
      borderRadius="6px"
    >
      {icon && icon}
      {text && (
        <CustomText
          text={text}
          size="14px"
          weight="500"
          color={bg ? "#fff" : "yess.neutral300"}
        />
      )}
    </Flex>
  );
};

const VISITORS = [
  {
    companyInfo: {
      name: "Saudi Arabian Military Industries",
      country: "Saudi Arabia",
      icon: SaudiMIco,
    },
    personalInfo: {
      name: "Guy Hawkins",
      email: "user@email.com",
      position: "Senior General",
      img: "",
    },
    badgeNo: "453263",
    badgeType: "VIP",
    type: "Media",
    status: "Approved",
  },
  {
    companyInfo: {
      name: "Lockheed Martin",
      country: "US",
      icon: USMIco,
    },
    personalInfo: {
      name: "John Doe",
      email: "john.doe@lockheedmartin.com",
      position: "Project Manager",
      img: "",
    },
    badgeNo: "453263",
    badgeType: "Organizer",
    type: "Contractor",
    status: "Rejected",
  },
  {
    companyInfo: {
      name: "BAE Systems",
      country: "UK",
      icon: UKMIco,
    },
    personalInfo: {
      name: "Alice Johnson",
      email: "alice.johnson@baesystems.com",
      position: "Director",
      img: "",
    },
    badgeNo: "453263",
    badgeType: "Delegation",
    type: "University",
    status: "Pending",
  },
  {
    companyInfo: {
      name: "Rheinmetall AG",
      country: "Germany",
      icon: GermanyMIco,
    },
    personalInfo: {
      name: "Hans Müller",
      email: "hans.mueller@rheinmetall.com",
      position: "Chief Engineer",
      img: "",
    },
    badgeNo: "-",
    badgeType: "-",
    type: "Defence",
    status: "Info Required",
  },
  {
    companyInfo: {
      name: "Bombardier",
      country: "Canada",
      icon: CanadaMIco,
    },
    personalInfo: {
      name: "Sophia Martinez",
      email: "sophia.martinez@bombardier.com",
      position: "Lead Developer",
      img: "",
    },
    badgeNo: "453263",
    badgeType: "Organizer",
    type: "Media",
    status: "Approved",
  },
];

export default VisitorTable;
