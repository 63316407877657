import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Navbar from "../components/Navbar";

function DashboardLayout() {
  return (
    <Flex maxH="100vh">
      <Sidebar />
      <Flex flexDir="column" w="100%">
        <Navbar />
        <Box bg="#F5F5F5" p="24px" overflowY="auto">
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
}

export default DashboardLayout;
