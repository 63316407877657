import React from "react";

function UploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="36"
      viewBox="0 0 44 36"
      fill="none"
    >
      <path
        d="M9.81149 14.271C5.40855 15.3181 2.13379 19.2766 2.13379 24C2.13379 29.5228 6.61094 34 12.1338 34C13.0812 34 13.9978 33.8683 14.8663 33.6221"
        stroke="#387DF5"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M34.1886 14.271C38.5916 15.3181 41.8663 19.2766 41.8663 24C41.8663 29.5228 37.3892 34 31.8663 34C30.9189 34 30.0023 33.8683 29.1338 33.6221"
        stroke="#387DF5"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M34.1338 14C34.1338 7.3726 28.7612 2 22.1338 2C15.5064 2 10.1338 7.3726 10.1338 14"
        stroke="#387DF5"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.1992 21.8812L22.1337 14.9238L29.2656 22.0002"
        stroke="#387DF5"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.1338 32.0001V18.4619"
        stroke="#387DF5"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default UploadIcon;
