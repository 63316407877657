import { Button, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import FormInput from "../../components/form/FormInput";
import CustomPhoneInput from "../../components/form/PhoneInput";
import IconGreenCheck from "../../svgs/IconGreenCheck";

function SendInvite() {
  const [inviteSent, setInviteSent] = useState({
    email: false,
    phone: false,
    whatsapp: false,
  });

  return (
    <Flex
      flexDir="column"
      gap="24px"
      maxW="497px"
      w="100%"
      mx="auto"
      minH="521px"
    >
      <CustomHeading text="Send invite" family="aeonik-bold" align="center" />
      <CustomText
        text="Send invite with given options below"
        align="center"
        color="yess.neutral600"
      />
      <Flex align="flex-end" gap="16px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Email" size="12px" />
          <FormInput ph="Enter Email" />
        </Flex>
        <Button
          h="38px"
          w="80px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          bg={inviteSent?.email ? "#D8FFF6" : "yess.secondary_gold"}
          _hover={{
            bg: inviteSent?.email ? "#D8FFF6" : "yess.secondary_gold",
          }}
          onClick={() => setInviteSent({ ...inviteSent, email: true })}
        >
          {inviteSent?.email ? (
            <IconGreenCheck />
          ) : (
            <CustomText text="Send" size="12px" color="#fff" />
          )}
        </Button>
      </Flex>
      <Flex align="flex-end" gap="16px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Phone" size="12px" />
          <CustomPhoneInput />
        </Flex>
        <Button
          h="38px"
          w="80px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          bg={inviteSent?.phone ? "#D8FFF6" : "yess.secondary_gold"}
          _hover={{
            bg: inviteSent?.phone ? "#D8FFF6" : "yess.secondary_gold",
          }}
          onClick={() => setInviteSent({ ...inviteSent, phone: true })}
        >
          {inviteSent?.phone ? (
            <IconGreenCheck />
          ) : (
            <CustomText text="Send" size="12px" color="#fff" />
          )}{" "}
        </Button>
      </Flex>
      <Flex align="flex-end" gap="16px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Whats App" size="12px" />
          <CustomPhoneInput />
        </Flex>
        <Button
          h="38px"
          w="80px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          bg={inviteSent?.whatsapp ? "#D8FFF6" : "yess.secondary_gold"}
          _hover={{
            bg: inviteSent?.whatsapp ? "#D8FFF6" : "yess.secondary_gold",
          }}
          onClick={() => setInviteSent({ ...inviteSent, whatsapp: true })}
        >
          {inviteSent?.whatsapp ? (
            <IconGreenCheck />
          ) : (
            <CustomText text="Send" size="12px" color="#fff" />
          )}{" "}
        </Button>
      </Flex>
    </Flex>
  );
}

export default SendInvite;
