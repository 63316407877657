import React from "react";

function OrganizationsIcon({ color }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.3332 3.56699C14.3332 4.17366 14.0465 4.71366 13.6065 5.06033C13.2865 5.31366 12.8799 5.46699 12.4332 5.46699C11.3799 5.46699 10.5332 4.62033 10.5332 3.56699C10.5332 3.12033 10.6865 2.72033 10.9399 2.39366H10.9465C11.2865 1.95366 11.8265 1.66699 12.4332 1.66699C13.4865 1.66699 14.3332 2.51366 14.3332 3.56699Z"
        stroke={color || "#655F5F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.4665 3.56699C5.4665 4.62033 4.61984 5.46699 3.5665 5.46699C3.11984 5.46699 2.71984 5.31366 2.39317 5.06033C1.95317 4.71366 1.6665 4.17366 1.6665 3.56699C1.6665 2.51366 2.51317 1.66699 3.5665 1.66699C4.17317 1.66699 4.71317 1.95366 5.05984 2.39366C5.31317 2.72033 5.4665 3.12033 5.4665 3.56699Z"
        stroke={color || "#655F5F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.3332 12.4332C14.3332 13.4865 13.4865 14.3332 12.4332 14.3332C11.8265 14.3332 11.2865 14.0465 10.9465 13.6065H10.9399C10.6865 13.2865 10.5332 12.8799 10.5332 12.4332C10.5332 11.3799 11.3799 10.5332 12.4332 10.5332C12.8799 10.5332 13.2799 10.6865 13.6065 10.9399V10.9465C14.0465 11.2865 14.3332 11.8265 14.3332 12.4332Z"
        stroke={color || "#655F5F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.4665 12.4332C5.4665 12.8799 5.31317 13.2799 5.05984 13.6065C4.71317 14.0532 4.17317 14.3332 3.5665 14.3332C2.51317 14.3332 1.6665 13.4865 1.6665 12.4332C1.6665 11.8265 1.95317 11.2865 2.39317 10.9465V10.9399C2.71317 10.6865 3.11984 10.5332 3.5665 10.5332C4.61984 10.5332 5.4665 11.3799 5.4665 12.4332Z"
        stroke={color || "#655F5F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.3332 8.00033C14.3332 9.06699 14.0732 10.0603 13.6065 10.9403C13.2865 10.687 12.8798 10.5337 12.4332 10.5337C11.3798 10.5337 10.5332 11.3803 10.5332 12.4337C10.5332 12.8803 10.6865 13.2803 10.9398 13.607C10.0598 14.0737 9.0665 14.3337 7.99984 14.3337C6.93984 14.3337 5.93984 14.0737 5.05984 13.607C5.31317 13.287 5.4665 12.8803 5.4665 12.4337C5.4665 11.3803 4.61984 10.5337 3.5665 10.5337C3.11984 10.5337 2.71984 10.687 2.39317 10.9403C1.9265 10.0603 1.6665 9.06699 1.6665 8.00033C1.6665 6.94033 1.9265 5.94033 2.39317 5.06033C2.71984 5.31366 3.11984 5.46699 3.5665 5.46699C4.61984 5.46699 5.4665 4.62033 5.4665 3.56699C5.4665 3.12033 5.31317 2.72033 5.05984 2.39366C5.93984 1.92699 6.93984 1.66699 7.99984 1.66699C9.0665 1.66699 10.0598 1.92699 10.9398 2.39366C10.6865 2.71366 10.5332 3.12033 10.5332 3.56699C10.5332 4.62033 11.3798 5.46699 12.4332 5.46699C12.8798 5.46699 13.2798 5.31366 13.6065 5.06033C14.0732 5.94033 14.3332 6.94033 14.3332 8.00033Z"
        stroke={color || "#655F5F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default OrganizationsIcon;
