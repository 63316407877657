import React from "react";

function MobileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
    >
      <path
        d="M4.39981 7.2C4.06843 7.2 3.79981 6.93137 3.79981 6.6C3.79981 6.26863 4.06843 6 4.39981 6H11.5998C11.9312 6 12.1998 6.26863 12.1998 6.6C12.1998 6.93137 11.9312 7.2 11.5998 7.2H4.39981ZM2.79981 24C1.69524 24 0.799805 23.1046 0.799805 22V2C0.799805 0.89543 1.69524 0 2.7998 0H13.1998C14.3044 0 15.1998 0.895431 15.1998 2V22C15.1998 23.1046 14.3044 24 13.1998 24H2.79981ZM2.8998 21C2.40275 21 1.9998 21.4029 1.9998 21.9C1.9998 22.3971 2.40275 22.8 2.8998 22.8H13.0998C13.5969 22.8 13.9998 22.3971 13.9998 21.9C13.9998 21.4029 13.5969 21 13.0998 21H2.8998ZM1.9998 17.8C1.9998 18.9046 2.89524 19.8 3.9998 19.8H11.9998C13.1044 19.8 13.9998 18.9046 13.9998 17.8V6.2C13.9998 5.09543 13.1044 4.2 11.9998 4.2H3.99981C2.89524 4.2 1.9998 5.09543 1.9998 6.2V17.8ZM1.9998 2.1C1.9998 2.59706 2.40275 3 2.8998 3H13.0998C13.5969 3 13.9998 2.59706 13.9998 2.1C13.9998 1.60294 13.5969 1.2 13.0998 1.2H2.8998C2.40275 1.2 1.9998 1.60294 1.9998 2.1Z"
        fill="black"
      />
    </svg>
  );
}

export default MobileIcon;
