import React from "react";

function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.10671 7.24634C8.04004 7.23967 7.96004 7.23967 7.88671 7.24634C6.30004 7.19301 5.04004 5.89301 5.04004 4.29301C5.04004 2.65967 6.36004 1.33301 8.00004 1.33301C9.63337 1.33301 10.96 2.65967 10.96 4.29301C10.9534 5.89301 9.69337 7.19301 8.10671 7.24634Z"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.77348 9.70699C3.16014 10.787 3.16014 12.547 4.77348 13.6203C6.60681 14.847 9.61348 14.847 11.4468 13.6203C13.0601 12.5403 13.0601 10.7803 11.4468 9.70699C9.62014 8.48699 6.61348 8.48699 4.77348 9.70699Z"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default UserIcon;
