import { Flex } from "@chakra-ui/react";
import React from "react";
import TicketSales from "./TicketSales";
import ExhibitorsAge from "./ExhibitorsAge";
import ExhibitorsCountry from "./ExhibitorsCountry";
import AssignedUsers from "./AssignedUsers";
import SubExhibitors from "./SubExhibitors";
import VisitorAnalytics from "./VisitorAnalytics";

function Analytics() {
  return (
    <div>
      <Flex gap="24px" align="center">
        <TicketSales />
        <ExhibitorsAge />
        <ExhibitorsCountry />
      </Flex>
      <Flex mt="24px" gap="24px">
        <AssignedUsers />
        <SubExhibitors />
        <VisitorAnalytics />
      </Flex>
    </div>
  );
}

export default Analytics;
