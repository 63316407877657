import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// Your data array
const DATA = [
  { title: "Titan Defense Solutions", value: 99 },
  { title: "Aegis Tactical Systems", value: 92 },
  { title: "Ironclad Defense Industries", value: 78 },
  { title: "Vanguard Armaments Corp", value: 53 },
  { title: "Sentinel Defense Technologies", value: 97 },
  { title: "Fortis Security Solutions", value: 50 },
  { title: "NovaTech Defense Systems", value: 80 },
  { title: "Horizon Military Solutions", value: 48 },
];

const RadarChartComponent = () => {
  return (
    <ResponsiveContainer width="60%" height={300}>
      <RadarChart outerRadius={150} data={DATA}>
        <PolarGrid />
        {/* Hide PolarAngleAxis to remove category labels */}
        <PolarAngleAxis dataKey="title" tick={false} />
        {/* Hide PolarRadiusAxis to remove value labels */}
        <PolarRadiusAxis angle={30} domain={[0, 100]} tick={false} />
        <Radar
          name="Performance"
          dataKey="value"
          stroke="#2EC19E"
          fill="#2EC19E"
          fillOpacity={0.2}
        />
        <Tooltip />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default RadarChartComponent;
