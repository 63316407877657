import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Divider, Flex, useMediaQuery } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import RadarChart from "../../components/charts/RadarChart";

function MostInteractedExhibitors() {
  const [isLessThan1700] = useMediaQuery("(max-width: 1700px)");
  return (
    <CustomCard w="100%" minH="403px">
      <Flex flexDir="column" gap="24px">
        <Flex align="center" gap="16px">
          <Flex gap="4px" align="center">
            <CustomText
              text="Most Interacted Exhibitors"
              wrap="nowrap"
              color="yess.base_black"
            />
            <CustomText wrap="nowrap" text="(Top 8)" color="yess.neutral400" />
          </Flex>
          <Divider bg="#F2F2F2" mt="5px" />
        </Flex>
        <Flex justify="space-between" align="center" gap="12px">
          <Flex
            flexDir="column"
            gap="12px"
            w="100%"
            minW="280px"
            maxW={isLessThan1700 ? "280px" : "100%"}
          >
            {DATA.map((data, index) => (
              <Flex
                key={index}
                align="center"
                justify="space-between"
                gap="8px"
              >
                <Flex gap="16px" align="center">
                  <Flex
                    height="20px"
                    width="20px"
                    borderRadius="4px"
                    bg="yess.secondary_light_gray"
                    align="center"
                    justify="center"
                  >
                    <CustomText
                      text={index + 1}
                      size="10px"
                      color="yess.neutral700"
                    />
                  </Flex>
                  <CustomText
                    text={data.title}
                    size="14px"
                    color="yess.neutral700"
                  />
                </Flex>
                <Flex
                  align="center"
                  justify="center"
                  h="26px"
                  w="49px"
                  borderRadius="99px"
                  bg="#2EC19E"
                >
                  <CustomText text={data.value} size="12px" color="#fff" />
                </Flex>
              </Flex>
            ))}
          </Flex>
          <Box h="300px" w="1px" bg="#f2f2f2" />
          <RadarChart />
        </Flex>
      </Flex>
    </CustomCard>
  );
}

const DATA = [
  {
    title: "Titan Defense Solutions",
    value: "99%",
  },
  {
    title: "Aegis Tactical Systems",
    value: "92%",
  },
  {
    title: "Ironclad Defense Industries",
    value: "78%",
  },
  {
    title: "Vanguard Armaments Corp",
    value: "53%",
  },
  {
    title: "Sentinel Defense Technologies",
    value: "97%",
  },
  {
    title: "Fortis Security Solutions",
    value: "50%",
  },
  {
    title: "NovaTech Defense Systems",
    value: "80%",
  },
  {
    title: "Horizon Military Solutions",
    value: "48%",
  },
];

export default MostInteractedExhibitors;
