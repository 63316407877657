import React, { useState } from "react";
import Chart from "react-apexcharts";

const TotalExhibitorsChart = () => {
  const [chartData] = useState<any>({
    series: [
      {
        name: "Value",
        data: [10, 30, 20, 27, 50, 40, 60], // Values for each day
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 2820,
        toolbar: {
          show: false, // Hide the toolbar
        },
      },
      stroke: {
        curve: "smooth", // Smooth the line
        width: 2,
      },
      xaxis: {
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], // Days of the week
      },
      colors: ["#387DF5"], // Customize the line color
      markers: {
        size: 4, // Marker size on data points
        colors: ["#387DF5"], // Marker color
      },
      tooltip: {
        enabled: true,
        x: { show: true },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        show: false,
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // Alternating row colors
          opacity: 0.5,
        },
      },
    },
  });

  return (
    <div className="w-full flex justify-center items-center mt-4">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={122}
      />
    </div>
  );
};

export default TotalExhibitorsChart;
