import React from "react";

function SmartGatesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.95252 2.66699H11.0478C11.4519 2.66699 11.8395 2.82754 12.1253 3.11331C12.411 3.39908 12.5716 3.78666 12.5716 4.1908V11.8098C12.5716 12.214 12.411 12.6016 12.1253 12.8873C11.8395 13.1731 11.4519 13.3337 11.0478 13.3337H4.95252C4.54838 13.3337 4.16079 13.1731 3.87502 12.8873C3.58925 12.6016 3.42871 12.214 3.42871 11.8098V4.1908C3.42871 3.78666 3.58925 3.39908 3.87502 3.11331C4.16079 2.82754 4.54838 2.66699 4.95252 2.66699Z"
        stroke="#BAB8B8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8208 13.3337L8.04361 11.8495C7.80027 11.7194 7.59681 11.5256 7.45498 11.2889C7.31314 11.0522 7.23824 10.7814 7.23828 10.5055V5.49518C7.23824 5.21923 7.31314 4.94845 7.45498 4.71175C7.59681 4.47504 7.80027 4.28129 8.04361 4.15118L10.8208 2.66699"
        stroke="#BAB8B8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.7619 8.76209C9.18269 8.76209 9.52381 8.42097 9.52381 8.00019C9.52381 7.5794 9.18269 7.23828 8.7619 7.23828C8.34112 7.23828 8 7.5794 8 8.00019C8 8.42097 8.34112 8.76209 8.7619 8.76209Z"
        fill="#BAB8B8"
      />
    </svg>
  );
}

export default SmartGatesIcon;
