import {
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import SaudiMIco from "../../assets/images/saudi-military.svg";
import USMIco from "../../assets/images/us-military.svg";
import UKMIco from "../../assets/images/uk-military.svg";
import GermanyMIco from "../../assets/images/germany-military.svg";
import CanadaMIco from "../../assets/images/canada-military.svg";
import CustomText from "../../components/text/CustomText";
import EyeIcon from "../../svgs/EyeIcon";
import MailIcon from "../../svgs/MailIcon";
import EditIcon from "../../svgs/EditIcon";
import ArrowLeft from "../../svgs/ArrowLeft";
import ArrowRight from "../../svgs/ArrowRight";

function OrganizationTable() {
  return (
    <Flex mt="16px" gap="20px" flexDir="column">
      <TableContainer overflowY="auto">
        <Table>
          <Thead>
            <Tr>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="300px"
                borderTopLeftRadius="12px"
              >
                <CustomText
                  text="Company Info"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="260px"
              >
                <CustomText
                  text="Country"
                  size="12px"
                  color="yess.neutral1000"
                  align="center"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="136px"
              >
                <CustomText
                  text="Type"
                  size="12px"
                  color="yess.neutral1000"
                  align="center"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="118px"
              >
                <CustomText
                  text="Vetting Status"
                  size="12px"
                  align="center"
                  color="yess.neutral1000"
                />
              </Th>

              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="148px"
                borderTopRightRadius="12px"
              >
                <CustomText
                  text="Action"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {ORGANIZATIONS?.map((exhi: any, idx: number) => (
              <Tr key={idx}>
                <Td borderLeft="1px solid #F2F2F2" p="16px 20px">
                  <Flex flexDir="column" gap="10px">
                    <Flex gap="10px" align="center">
                      <Image
                        src={exhi.companyInfo?.icon}
                        alt={exhi?.companyInfo?.name}
                      />
                      <Flex flexDir="column" gap="4px">
                        <CustomText
                          text={exhi?.companyInfo?.name}
                          color="yess.neutral1000"
                        />
                        <CustomText
                          text={exhi?.companyInfo?.country}
                          color="yess.neutral500"
                          size="12px"
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Td>
                <Td
                  borderLeft="1px solid #f2f2f2"
                  p="16px 20px"
                  verticalAlign="center"
                >
                  <CustomText
                    text={exhi?.companyInfo?.country}
                    color="yess.neutral800"
                    size="14px"
                    align="center"
                  />
                </Td>
                <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                  <CustomText
                    text={exhi?.type}
                    size="14px"
                    color="yess.neutral800"
                    align="center"
                  />
                </Td>
                <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                  <Badge status={exhi.status} />
                </Td>
                <Td
                  borderLeft="1px solid #f2f2f2"
                  borderRight="1px solid #f2f2f2"
                  p="16px 20px"
                >
                  <Flex align="center" gap="6px">
                    <ActionButton icon={<EyeIcon />} />
                    <ActionButton icon={<MailIcon />} />
                    <ActionButton
                      bg="yess.secondary_gold"
                      icon={<EditIcon />}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex align="center" gap="16px" justify="end">
        <CustomText text="1-10 of 52" size="13px" color="yess.neutral300" />
        <Flex align="center" gap="2px">
          <PaginationButton icon={<ArrowLeft />} />
          <PaginationButton text="1" />
          <PaginationButton text="2" bg="yess.secondary_gold" />
          <PaginationButton text="3" />
          <PaginationButton text="4" />
          <PaginationButton text="5" />
          <PaginationButton icon={<ArrowRight />} />
        </Flex>
      </Flex>
    </Flex>
  );
}

const ActionButton = ({ icon, bg }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="32px"
      w="32px"
      bg={bg || "yess.secondary_light_gray"}
      borderRadius="8px"
    >
      {icon}
    </Flex>
  );
};

const Badge = ({ status }: any) => {
  const getBgColor = () => {
    switch (status) {
      case "Approved":
        return "#F1FFFC";
      case "Rejected":
        return "#FFE9EB";
      case "Pending":
        return "#FFF9F1";
      case "Info Required":
        return "#F9F9F9";
      default:
        return "yess.secondary_light_gray";
    }
  };

  const getBorderColor = () => {
    switch (status) {
      case "Approved":
        return "#2EC19E";
      case "Rejected":
        return "#ED3D4E";
      case "Pending":
        return "#EDA145";
      case "Info Required":
        return "#4E4949";
      default:
        return "yess.base_black";
    }
  };

  return (
    <Flex
      align="center"
      justify="center"
      w="124px"
      h="30px"
      borderRadius="99px"
      border="1px solid"
      borderColor={getBorderColor()}
      bg={getBgColor()}
      mx="auto"
    >
      <CustomText text={status} size="12px" color={getBorderColor()} />
    </Flex>
  );
};

const PaginationButton = ({ text, bg, icon }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="30px"
      w="30px"
      bg={bg || "#fff"}
      borderRadius="6px"
    >
      {icon && icon}
      {text && (
        <CustomText
          text={text}
          size="14px"
          weight="500"
          color={bg ? "#fff" : "yess.neutral300"}
        />
      )}
    </Flex>
  );
};

const ORGANIZATIONS = [
  {
    companyInfo: {
      name: "Saudi Arabian Military Industries",
      country: "Saudi Arabia",
      icon: SaudiMIco,
    },
    type: "Media",
    status: "Approved",
  },
  {
    companyInfo: {
      name: "Lockheed Martin",
      country: "US",
      icon: USMIco,
    },
    type: "Contractor",
    status: "Rejected",
  },
  {
    companyInfo: {
      name: "BAE Systems",
      country: "UK",
      icon: UKMIco,
      email: "info@baesystems.com",
      phone: "0044 1252 373232",
      location:
        "Warwick House, Farnborough Aerospace Centre, Farnborough, Hampshire, UK",
    },
    type: "University",
    status: "Pending",
  },
  {
    companyInfo: {
      name: "Rheinmetall AG",
      country: "Germany",
      icon: GermanyMIco,
      email: "info@rheinmetall.com",
      phone: "0049 211 473-01",
      location: "Rheinmetall Platz 1, 40476 Düsseldorf, Germany",
    },
    type: "Defence",
    status: "Info Required",
  },
  {
    companyInfo: {
      name: "Bombardier",
      country: "Canada",
      icon: CanadaMIco,
      email: "info@bombardier.com",
      phone: "001 514-861-9481",
      location: "800 René-Lévesque Blvd. West, Montréal, Québec, Canada",
    },
    type: "Media",
    status: "Approved",
  },
];

export default OrganizationTable;
