import React from "react";

function SingleUserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.1596 10.8701C12.0596 10.8601 11.9396 10.8601 11.8296 10.8701C9.44957 10.7901 7.55957 8.84006 7.55957 6.44006C7.55957 3.99006 9.53957 2.00006 11.9996 2.00006C14.4496 2.00006 16.4396 3.99006 16.4396 6.44006C16.4296 8.84006 14.5396 10.7901 12.1596 10.8701Z"
        stroke="#0B0A0A"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.15973 14.5599C4.73973 16.1799 4.73973 18.8199 7.15973 20.4299C9.90973 22.2699 14.4197 22.2699 17.1697 20.4299C19.5897 18.8099 19.5897 16.1699 17.1697 14.5599C14.4297 12.7299 9.91973 12.7299 7.15973 14.5599Z"
        stroke="#0B0A0A"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SingleUserIcon;
