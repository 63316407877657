import React from "react";

function QRIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.3335 5.99967V4.33301C1.3335 2.67301 2.6735 1.33301 4.3335 1.33301H6.00016"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 1.33301H11.6667C13.3267 1.33301 14.6667 2.67301 14.6667 4.33301V5.99967"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6665 10.667V11.667C14.6665 13.327 13.3265 14.667 11.6665 14.667H10.6665"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.00016 14.6667H4.3335C2.6735 14.6667 1.3335 13.3267 1.3335 11.6667V10"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3332 6.33301V9.66634C11.3332 10.9997 10.6665 11.6663 9.33317 11.6663H6.6665C5.33317 11.6663 4.6665 10.9997 4.6665 9.66634V6.33301C4.6665 4.99967 5.33317 4.33301 6.6665 4.33301H9.33317C10.6665 4.33301 11.3332 4.99967 11.3332 6.33301Z"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.6668 8H3.3335"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default QRIcon;
