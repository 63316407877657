import React from "react";

function PencilEdit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M9.85534 2.6114L12.0815 4.83753M8.37126 13H14.3076M2.43491 10.0318L1.69287 13L4.66104 12.258L13.2584 3.66065C13.5366 3.38234 13.6929 3.00493 13.6929 2.6114C13.6929 2.21787 13.5366 1.84046 13.2584 1.56215L13.1307 1.43452C12.8524 1.1563 12.475 1 12.0815 1C11.6879 1 11.3105 1.1563 11.0322 1.43452L2.43491 10.0318Z"
        stroke="#387DF5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default PencilEdit;
