import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Avatar, Circle, Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import TwoUserIcon from "../../svgs/TwoUserIcon";

function SubExhibitors() {
  return (
    <CustomCard>
      <Flex flexDir="column" justify="space-between" minH="282px">
        <Flex flexDir="column" gap="24px">
          <Flex gap="16px" align="center">
            <Flex
              align="center"
              justify="center"
              h="40px"
              w="40px"
              bg={"yess.secondary_light_gray"}
              borderRadius="8px"
            >
              <TwoUserIcon />
            </Flex>
            <CustomText
              text="Sub Exhibitors"
              color="yess.base_black"
              size="18px"
            />
          </Flex>
          <CustomText text="8,654" size="32px" color="yess.base_black" />
        </Flex>
        <Flex align="center">
          <Avatar boxSize="55px" border="3px solid #fff" />
          <Avatar boxSize="55px" border="3px solid #fff" ml="-20px" />
          <Avatar boxSize="55px" border="3px solid #fff" ml="-20px" />
          <Avatar boxSize="55px" border="3px solid #fff" ml="-20px" />
          <Circle
            size="55px"
            border="3px solid #fff"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="#387DF5"
            ml="-20px"
            zIndex="10"
          >
            <CustomText text="+8k" color="#fff" weight="700" />
          </Circle>
        </Flex>
      </Flex>
    </CustomCard>
  );
}

export default SubExhibitors;
