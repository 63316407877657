import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const RevenueTrackingChart = () => {
  const [options] = useState<any>({
    series: [65, 25, 10],
    chart: {
      width: 300,
      type: "pie",
    },
    labels: [
      "Marketing and Advertising",
      "Logistics",
      "Sponsorship and Partnerships",
    ], // Two labels corresponding to the two series
    legend: {
      show: false,
    },
    colors: ["#387DF5", "#D79A2B", "#2EC19E"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={options.series}
        type="pie"
        width={300}
      />
    </div>
  );
};

export default RevenueTrackingChart;
