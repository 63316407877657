import React from "react";

function CrowdIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.0002 4.77301C11.9602 4.76634 11.9135 4.76634 11.8735 4.77301C10.9535 4.73968 10.2202 3.98634 10.2202 3.05301C10.2202 2.09968 10.9869 1.33301 11.9402 1.33301C12.8935 1.33301 13.6602 2.10634 13.6602 3.05301C13.6535 3.98634 12.9202 4.73968 12.0002 4.77301Z"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3135 9.62645C12.2268 9.77978 13.2335 9.61978 13.9401 9.14645C14.8801 8.51978 14.8801 7.49312 13.9401 6.86645C13.2268 6.39312 12.2068 6.23311 11.2935 6.39311"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.97983 4.77301C4.01983 4.76634 4.06649 4.76634 4.10649 4.77301C5.02649 4.73968 5.75982 3.98634 5.75982 3.05301C5.75982 2.09968 4.99316 1.33301 4.03983 1.33301C3.08649 1.33301 2.31982 2.10634 2.31982 3.05301C2.32649 3.98634 3.05983 4.73968 3.97983 4.77301Z"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66663 9.62645C3.75329 9.77978 2.74663 9.61978 2.03996 9.14645C1.09996 8.51978 1.09996 7.49312 2.03996 6.86645C2.75329 6.39312 3.77329 6.23311 4.68663 6.39311"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99973 9.75348C7.95973 9.74681 7.91306 9.74681 7.87306 9.75348C6.95306 9.72015 6.21973 8.96681 6.21973 8.03348C6.21973 7.08014 6.98639 6.31348 7.93973 6.31348C8.89306 6.31348 9.65973 7.08681 9.65973 8.03348C9.65306 8.96681 8.91973 9.72681 7.99973 9.75348Z"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.05998 11.8542C5.11998 12.4809 5.11998 13.5076 6.05998 14.1342C7.12665 14.8476 8.87331 14.8476 9.93998 14.1342C10.88 13.5076 10.88 12.4809 9.93998 11.8542C8.87998 11.1476 7.12665 11.1476 6.05998 11.8542Z"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CrowdIcon;
