import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const VisitorLineChart = () => {
  const [series] = useState([
    {
      name: "series1",
      data: [250, 240, 550, 490, 515, 320, 520, 480],
    },
  ]);

  const [options] = useState<any>({
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Organizer",
        "Exhibitor",
        "Ministry of defence",
        "Contractor",
        "Media",
        "Academic staff",
        "Student",
        "Staff",
      ],
    },

    grid: {
      borderColor: "#F2F2F2",
      strokeDashArray: 7,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={218}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default VisitorLineChart;
