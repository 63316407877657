import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Flex, Input } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import RotateIcon from "../../svgs/RotateIcon";
import { IconChevronDown } from "@tabler/icons-react";
import SortIcon from "../../svgs/SortIcon";
import SearchIcon from "../../svgs/SearchIcon";
import BoothTable from "./BoothTable";
import BoothStats from "./BoothStats";

function Booths() {
  return (
    <div>
      <CustomCard>
        <Flex align="center" justify="space-between">
          <Flex flexDir="column" w="100%">
            <CustomText text="Booths" size="18px" color="yess.base_black" />
            <Flex gap="8px" align="center">
              <CustomText
                text="Last synced: Sept 15, 2024 10:30 AM"
                size="12px"
              />
              <Flex
                align="center"
                gap="6px"
                px="8px"
                h="24px"
                w="89px"
                bg="yess.secondary_light_gray"
                borderRadius="5px"
              >
                <CustomText
                  text="Sync Data"
                  size="12px"
                  color="yess.secondary_blue"
                />
                <RotateIcon />
              </Flex>
              <Flex
                align="center"
                gap="12px"
                px="8px"
                h="24px"
                w="112px"
                bg="yess.secondary_light_gray"
                borderRadius="5px"
              >
                <CustomText
                  text="Every 10 mins"
                  size="12px"
                  color="yess.nuetral900"
                />
                <IconChevronDown size="12px" color="#000000" />
              </Flex>
            </Flex>
          </Flex>
          <Flex align="center" gap="10px" w="100%" justify="end">
            <Flex
              ps="6px"
              pe="16px"
              py="8px"
              height="48px"
              maxW="360px"
              w="100%"
              borderRadius="12px"
              border="1px solid"
              borderColor="yess.border"
              bg="yess.secondary_light_gray"
              align="center"
              gap="8px"
            >
              <Flex
                minH="28px"
                minW="28px"
                borderRadius="4px"
                bg="#fff"
                align="center"
                justify="center"
              >
                <SortIcon />
              </Flex>
              <Input
                placeholder="Search..."
                color="yess.neutral900"
                fontSize="12px"
                fontWeight="400"
                pl="0px"
                className="aeonik-regular"
                _placeholder={{
                  color: "yess.neutral700",
                }}
                bg="transparent"
                border="none"
                _focus={{ border: "none" }}
                _hover={{ border: "none" }}
                _focusVisible={{ border: "none" }}
              />
              <SearchIcon />
            </Flex>
          </Flex>
        </Flex>
        <BoothTable />
      </CustomCard>
      <BoothStats />
    </div>
  );
}

export default Booths;
