import React from "react";
import Chart from "react-apexcharts";

const DigitalSignageChart = () => {
  const options: any = {
    chart: {
      type: "pie",
    },
    labels: ["Ads", "Info", "Event"],
    colors: ["#D79A2B", "#387DF5", "#2EC19E"],
    legend: {
      position: "bottom",
      show: false,
    },
  };

  const series = [96, 70, 37]; // Data for the chart

  return (
    <div className="w-full flex justify-center items-center">
      <Chart options={options} series={series} type="pie" width="230" />
    </div>
  );
};

export default DigitalSignageChart;
