import React from "react";

function IconPlus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
    >
      <path
        d="M7.5 28H49.5"
        stroke="#001C70"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.5 49V7"
        stroke="#001C70"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default IconPlus;
