import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import Event1Img from "../../assets/images/digital-signage/event-1.png";
import Event2Img from "../../assets/images/digital-signage/event-2.png";
import Event3Img from "../../assets/images/digital-signage/event-3.png";
import Event4Img from "../../assets/images/digital-signage/event-4.png";
import PencilEdit from "../../svgs/PencilEdit";

function EventScreens() {
  return (
    <Flex mt="16px" gap="16px" flexDir="column">
      <CustomText text="Event Screens" color="yess.base_black" />
      <Flex gap="24px">
        {EVENTs.map((event, index) => (
          <EVENT_CARD key={index} event={event} />
        ))}
      </Flex>
    </Flex>
  );
}

const EVENT_CARD = ({ event }: any) => {
  return (
    <Flex
      flexDir="column"
      gap="24px"
      p="16px"
      borderRadius="12px"
      border="1px solid"
      borderColor="yess.secondary_light_gray"
    >
      <Box pos="relative">
        <Image
          src={event?.img}
          alt="ad1"
          // maxW="242px"
          // maxH="160px"
          objectFit="cover"
        />
        <Flex
          align="center"
          justify="center"
          h="32px"
          w="32px"
          bg="rgba(255, 255, 255, 0.90)"
          borderRadius="8px"
          pos="absolute"
          top="0"
          right="0"
          mt="10px"
          mr="10px"
        >
          <PencilEdit />
        </Flex>
      </Box>
      <Flex flexDir="column" gap="12px">
        <CustomText text={event?.title} color="yess.base_black" />
        <CustomText text={event?.desc} color="yess.neutral500" size="14px" />
      </Flex>
    </Flex>
  );
};

const EVENTs = [
  {
    img: Event1Img,
    title: "Event #1",
    desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fug...",
  },
  {
    img: Event2Img,
    title: "Event #2",
    desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fug...",
  },
  {
    img: Event3Img,
    title: "Event #3",
    desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fug...",
  },
  {
    img: Event4Img,
    title: "Event #4",
    desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fug...",
  },
];

export default EventScreens;
