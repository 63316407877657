import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import Logo from "../../assets/images/Logo.svg";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import TreeIco from "../../assets/images/tree.svg";
import IconPlus from "../../svgs/IconPlus";
import GalleryImg from "../../svgs/GalleryImg";
import IconChevDown from "../../svgs/IconChevDown";
import IconCalendar from "../../svgs/IconCalendar";
import CustomButton from "../../components/buttons/CustomButton";
import { IconCheck } from "@tabler/icons-react";
// Date Picker
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import IconEdit from "../../svgs/IconEdit";

function CreateEvent() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const imgRef = useRef<any>(null);

  const [isEventTypeOpen, setIsEventTypeOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [eventForm, setEventForm] = useState<any>({
    eventType: "",
    date: [],
    description: "",
    image: "",
  });
  const [events, setEvents] = useState([
    {
      eventType: "",
      date: [],
      description: "",
      image: "",
      id: 1,
    },
    {
      eventType: "",
      date: [],
      description: "",
      image: "",
      id: 2,
    },
    {
      eventType: "",
      date: [],
      description: "",
      image: "",
      id: 3,
    },
    {
      eventType: "",
      date: [],
      description: "",
      image: "",
      id: 4,
    },
    {
      eventType: "",
      date: [],
      description: "",
      image: "",
      id: 5,
    },
  ]);

  const handleCreateEvent = () => {
    if (!eventForm?.eventType) {
      toast({
        title: "Please select event type",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else if (!eventForm?.date) {
      toast({
        title: "Please select date",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else if (!eventForm?.description) {
      toast({
        title: "Please add description",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setEvents((prevEvents) => {
        // Create a copy of the previous events array
        const newEvents = [...prevEvents];

        // Check if the current index is within the array bounds
        if (currentEventIndex < newEvents.length) {
          // Update the event at the current index
          newEvents[currentEventIndex] = {
            ...newEvents[currentEventIndex],
            ...eventForm,
          };
        } else {
          toast({
            title: "Event creation failed",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }

        return newEvents;
      });

      // Increment the current index for the next submission
      setCurrentEventIndex((prevIndex) => prevIndex + 1);

      // Close the modal
      onClose();

      // Reset the form for the next input
      setEventForm({
        eventType: "",
        date: [],
        description: "",
        image: "",
      });
    }
  };

  return (
    <Box p="26px 56px" pos="relative" minH="100vh">
      <Image src={Logo} alt="logo" w="144px" h="47px" draggable={false} />
      <Flex gap="1px" flexDir="column" mt="37px">
        <CustomHeading text="Welcome to Exhibitor Platform" size="36px" />
        <CustomText
          text={`Host Your Organization's Next Big Event: Add to the Upcoming Schedule`}
          color="yess.neutral600"
        />
      </Flex>
      <Flex mt="44px" align="center" gap="24px">
        {events.map((event, idx) =>
          !event?.eventType ? (
            <Flex
              key={idx}
              minW="177px"
              minH="430px"
              align="center"
              justify="center"
              borderRadius="24px"
              border="2px dashed"
              borderColor={
                idx === 0 ? "yess.secondary_blue" : "yess.primary_gray"
              }
              bg={idx === 0 ? "rgba(56, 125, 245, 0.04)" : "transparent"}
              cursor="pointer"
              onClick={onOpen}
            >
              <IconPlus />
            </Flex>
          ) : (
            <Flex
              key={event.id}
              w="100%"
              maxW="360px"
              minH="430px"
              borderRadius="8px"
              border="1px solid"
              borderColor="#F2F2F2"
              boxShadow="0px 2px 9.8px 0px rgba(0, 0, 0, 0.29)"
              gap="24px"
              flexDir="column"
            >
              <Image
                src={event?.image}
                maxH="207px"
                objectFit="cover"
                alt="img"
                borderRadius="8px"
              />
              <Flex flexDir="column" gap="12px" p="12px">
                <Flex gap="12px" align="center" justify="space-between">
                  <CustomHeading
                    text={event?.eventType}
                    family="aeonik-regular"
                    size="20px"
                  />
                  <IconEdit />
                </Flex>
                <CustomText
                  text={
                    event?.date?.length > 1
                      ? `${new Date(
                          event?.date[0]
                        )?.toDateString()} - ${new Date(
                          event?.date[1]
                        )?.toDateString()}`
                      : new Date(event?.date[0])?.toDateString()
                  }
                  size="14px"
                  color="yess.base_black"
                />
                <CustomText
                  text={event?.description}
                  size="14px"
                  color="yess.base_black"
                />
              </Flex>
            </Flex>
          )
        )}
      </Flex>
      <Image
        src={TreeIco}
        alt="tree"
        pos="absolute"
        right="0"
        bottom="0"
        maxH="556px"
        draggable={false}
      />

      {/* Modal For Adding Events */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          borderRadius="24px"
          border="1px solid"
          borderColor="yess.neutral100"
          p="32px 30px"
          maxW="480px"
          mt="20px"
        >
          <ModalBody p="0">
            <CustomHeading
              text="Create Event"
              size="24px"
              family="aenoik-regular"
              align="center"
            />
            <Flex mt="30px" gap="22px" flexDir="column">
              <Flex
                align="center"
                justify="center"
                minH="207px"
                borderRadius="8px"
                bg="yess.secondary_light_gray"
                onClick={() => imgRef.current.click()}
              >
                {eventForm?.image ? (
                  <Image
                    src={eventForm?.image}
                    alt="event"
                    w="100%"
                    maxH="207px"
                    objectFit="cover"
                    borderRadius="8px"
                  />
                ) : (
                  <GalleryImg />
                )}
              </Flex>
              <input
                type="file"
                hidden
                ref={imgRef}
                onChange={(e: any) => {
                  const file = e.target.files[0];
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    setEventForm({ ...eventForm, image: reader.result });
                  };
                  reader.readAsDataURL(file);
                }}
              />
              <Flex gap="8px" flexDir="column">
                <CustomText text="Event Type" size="12px" />
                <Box pos="relative">
                  <Flex
                    align="center"
                    justify="space-between"
                    px="24px"
                    h="38px"
                    bg="yess.secondary_light_gray"
                    borderRadius="8px"
                    cursor="pointer"
                    onClick={() => setIsEventTypeOpen(!isEventTypeOpen)}
                  >
                    <CustomText
                      text={eventForm?.eventType || "Choose Event Type"}
                      size="12px"
                      color="yess.neutral600"
                    />
                    <IconChevDown />
                  </Flex>
                  <Flex
                    flexDir="column"
                    gap="12px"
                    p="16px"
                    borderRadius="8px"
                    border="1px solid"
                    borderColor="yess.primary_gray"
                    bg="yess.secondary_light_gray"
                    pos="absolute"
                    top="0"
                    mt="42px"
                    w="100%"
                    zIndex="100"
                    display={isEventTypeOpen ? "flex" : "none"}
                  >
                    <Flex
                      justify="space-between"
                      py="6px"
                      onClick={() => {
                        setEventForm({ ...eventForm, eventType: "" });
                        setIsEventTypeOpen(false);
                      }}
                    >
                      <CustomText
                        text="Choose Event Type"
                        size="14px"
                        color="yess.base_black"
                      />
                    </Flex>
                    {EVENT_TYPES?.map((type, idx) => (
                      <Flex
                        justify="space-between"
                        py="6px"
                        key={idx}
                        cursor="pointer"
                        onClick={() => {
                          setEventForm({ ...eventForm, eventType: type });
                          setIsEventTypeOpen(false);
                        }}
                      >
                        <CustomText
                          text={type || ""}
                          size="14px"
                          color={
                            eventForm?.eventType === type
                              ? "yess.base_black"
                              : "yess.neutral600"
                          }
                        />
                        {eventForm?.eventType === type && (
                          <IconCheck size="16px" color="#2EC19E" />
                        )}
                      </Flex>
                    ))}
                  </Flex>
                </Box>
              </Flex>
              <Flex gap="8px" flexDir="column">
                <CustomText text="Date" size="12px" />
                <Box pos="relative">
                  <Flex
                    align="center"
                    justify="space-between"
                    px="24px"
                    h="38px"
                    bg="yess.secondary_light_gray"
                    borderRadius="8px"
                    cursor="pointer"
                    onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                  >
                    <CustomText
                      text={
                        eventForm?.date
                          ? eventForm?.date?.length > 1
                            ? `${eventForm?.date[0]?.toDateString()} - ${eventForm?.date[1]?.toDateString()}`
                            : eventForm?.date[0]?.toDateString()
                          : "Select Date"
                      }
                      size="12px"
                      color="yess.neutral600"
                    />
                    <IconCalendar />
                  </Flex>
                  <Box
                    pos="absolute"
                    top="0"
                    mt="42px"
                    zIndex="100"
                    display={isCalendarOpen ? "flex" : "none"}
                  >
                    <Calendar
                      onChange={(val: any) => {
                        setEventForm({ ...eventForm, date: val });
                      }}
                      value={eventForm?.date}
                      selectRange
                    />
                  </Box>
                </Box>
              </Flex>
              <Flex gap="8px" flexDir="column">
                <CustomText text="Description" size="12px" />
                <Textarea
                  placeholder="Add Description"
                  p="14px 24px"
                  bg="yess.secondary_light_gray"
                  borderRadius="8px"
                  fontSize="12px"
                  color="yess.base_black"
                  className="aeonik-regular"
                  fontWeight="400"
                  border="none"
                  _placeholder={{
                    color: "yess.neutral600",
                  }}
                  _focus={{
                    border: "none",
                  }}
                  _focusVisible={{
                    border: "none",
                  }}
                  value={eventForm?.description}
                  onChange={(e) =>
                    setEventForm({ ...eventForm, description: e.target.value })
                  }
                />
              </Flex>
              <Box mt="28px">
                <CustomButton
                  btnTitle="Create Event"
                  onClick={handleCreateEvent}
                />
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

const EVENT_TYPES = [
  "World Defence Show",
  "Air Exhibition",
  "Naval Defence",
  "International Defence Exhibition",
];

export default CreateEvent;
