import {
  Avatar,
  Box,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import SaudiMIco from "../../assets/images/saudi-military.svg";
import USMIco from "../../assets/images/us-military.svg";
import UKMIco from "../../assets/images/uk-military.svg";
import GermanyMIco from "../../assets/images/germany-military.svg";
import CanadaMIco from "../../assets/images/canada-military.svg";
import CustomText from "../../components/text/CustomText";
import { IconMail, IconMapPin, IconPhone } from "@tabler/icons-react";
import EyeIcon from "../../svgs/EyeIcon";
import MailIcon from "../../svgs/MailIcon";
import EditIcon from "../../svgs/EditIcon";
import ArrowLeft from "../../svgs/ArrowLeft";
import ArrowRight from "../../svgs/ArrowRight";

function ExhibitorsTable() {
  return (
    <Flex mt="16px" gap="20px" flexDir="column">
      <TableContainer overflowY="auto">
        <Table>
          <Thead>
            <Tr>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="300px"
                borderTopLeftRadius="12px"
              >
                <CustomText
                  text="Company Info"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="260px"
              >
                <CustomText
                  text="Personal Info"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="136px"
              >
                <CustomText
                  text="Location"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="118px"
              >
                <CustomText
                  text="Assigned Users"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="118px"
              >
                <CustomText
                  text="Utilized Badges"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                minW="148px"
                borderTopRightRadius="12px"
              >
                <CustomText
                  text="Action"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {EXHIBITORS?.map((exhi: any, idx: number) => (
              <Tr key={idx}>
                <Td borderLeft="1px solid #F2F2F2" p="16px 20px">
                  <Flex flexDir="column" gap="10px">
                    <Flex gap="10px" align="center">
                      <Image
                        src={exhi.companyInfo?.icon}
                        alt={exhi?.companyInfo?.name}
                      />
                      <Flex flexDir="column" gap="4px">
                        <CustomText
                          text={exhi?.companyInfo?.name}
                          color="yess.neutral1000"
                        />
                        <CustomText
                          text={exhi?.companyInfo?.country}
                          color="yess.neutral500"
                          size="12px"
                        />
                      </Flex>
                    </Flex>
                    <Flex
                      flexDir="column"
                      pt="16px"
                      ps="10px"
                      gap="12px"
                      borderTop="1px solid #F2F2F2"
                      maxW="294px"
                    >
                      <Flex align="center" gap="10px">
                        <div>
                          <IconMail color="#4E4949" size="11px" />
                        </div>
                        <Box w="1px" h="14px" bg="#F2F2F2" />
                        <CustomText
                          text={exhi?.companyInfo?.email}
                          color="yess.neutral500"
                          size="12px"
                          lh="19px"
                        />
                      </Flex>
                      <Flex align="center" gap="10px">
                        <div>
                          <IconPhone color="#4E4949" size="11px" />
                        </div>
                        <Box w="1px" h="14px" bg="#F2F2F2" />
                        <CustomText
                          text={exhi?.companyInfo?.phone}
                          color="yess.neutral500"
                          size="12px"
                          lh="19px"
                        />
                      </Flex>
                      <Flex align="center" gap="10px">
                        <div>
                          <IconMapPin color="#4E4949" size="11px" />
                        </div>
                        <Box w="1px" h="14px" bg="#F2F2F2" />
                        <CustomText
                          text={exhi?.companyInfo?.location}
                          color="yess.neutral500"
                          size="12px"
                          lh="19px"
                          wrap="wrap"
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Td>
                <Td
                  borderLeft="1px solid #f2f2f2"
                  p="6px 20px"
                  verticalAlign="baseline"
                >
                  {exhi?.personalInfo?.map((person: any, idx: number) => (
                    <Flex
                      key={idx}
                      gap="10px"
                      py="10px"
                      align="center"
                      borderBottom={
                        idx === exhi.personalInfo.length - 1
                          ? "none"
                          : "1px solid #F2F2F2"
                      }
                    >
                      <Avatar size="sm" />
                      <Flex flexDir="column" gap="4px">
                        <Flex align="center" gap="4px">
                          <CustomText
                            text={person?.name}
                            color="yess.neutral1000"
                          />
                          <CustomText
                            text={`(${person?.position})`}
                            color="yess.neutral500"
                            size="12px"
                          />
                        </Flex>
                        <CustomText
                          text={person?.email}
                          color="yess.neutral500"
                          size="12px"
                        />
                      </Flex>
                    </Flex>
                  ))}
                </Td>
                <Td
                  borderLeft="1px solid #f2f2f2"
                  p="16px 20px"
                  verticalAlign="top"
                >
                  <Flex align="center" gap="12px">
                    <Flex flexDir="column" gap="4px">
                      <CustomText
                        text="Hall"
                        size="10px"
                        color="yess.neutral800"
                      />
                      <CustomText
                        text={"#" + exhi?.location?.hall}
                        size="14px"
                        color="yess.neutral500"
                      />
                    </Flex>
                    <Flex flexDir="column" gap="4px">
                      <CustomText
                        text="Stand"
                        size="10px"
                        color="yess.neutral800"
                      />
                      <CustomText
                        text={"#" + exhi?.location?.stand}
                        size="14px"
                        color="yess.neutral500"
                      />
                    </Flex>
                    <Flex flexDir="column" gap="4px">
                      <CustomText
                        text="Pavilion"
                        size="10px"
                        color="yess.neutral800"
                      />
                      <CustomText
                        text={"#" + exhi?.location?.pavillion}
                        size="14px"
                        color="yess.neutral500"
                      />
                    </Flex>
                  </Flex>
                </Td>
                <Td
                  borderLeft="1px solid #f2f2f2"
                  p="16px 20px"
                  verticalAlign="top"
                >
                  <CustomText
                    text={exhi?.assignedUsers}
                    size="14px"
                    color="yess.neutral800"
                    align="center"
                  />
                </Td>
                <Td
                  borderLeft="1px solid #f2f2f2"
                  p="16px 20px"
                  verticalAlign="top"
                >
                  <CustomText
                    text={exhi?.utilizedBadges}
                    size="14px"
                    color="yess.neutral800"
                    align="center"
                  />
                </Td>
                <Td
                  borderLeft="1px solid #f2f2f2"
                  borderRight="1px solid #f2f2f2"
                  p="16px 20px"
                  verticalAlign="top"
                >
                  <Flex align="center" gap="6px">
                    <ActionButton icon={<EyeIcon />} />
                    <ActionButton icon={<MailIcon />} />
                    <ActionButton
                      bg="yess.secondary_gold"
                      icon={<EditIcon />}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex align="center" gap="16px" justify="end">
        <CustomText text="1-10 of 52" size="13px" color="yess.neutral300" />
        <Flex align="center" gap="2px">
          <PaginationButton icon={<ArrowLeft />} />
          <PaginationButton text="1" />
          <PaginationButton text="2" bg="yess.secondary_gold" />
          <PaginationButton text="3" />
          <PaginationButton text="4" />
          <PaginationButton text="5" />
          <PaginationButton icon={<ArrowRight />} />
        </Flex>
      </Flex>
    </Flex>
  );
}

const ActionButton = ({ icon, bg }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="32px"
      w="32px"
      bg={bg || "yess.secondary_light_gray"}
      borderRadius="8px"
    >
      {icon}
    </Flex>
  );
};

const PaginationButton = ({ text, bg, icon }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="30px"
      w="30px"
      bg={bg || "#fff"}
      borderRadius="6px"
    >
      {icon && icon}
      {text && (
        <CustomText
          text={text}
          size="14px"
          weight="500"
          color={bg ? "#fff" : "yess.neutral300"}
        />
      )}
    </Flex>
  );
};

const EXHIBITORS = [
  {
    companyInfo: {
      name: "Saudi Arabian Military Industries",
      country: "Saudi Arabia",
      icon: SaudiMIco,
      email: "info@saudi-military.sa",
      phone: "00966 408-1628 8524",
      location: "Al Dabab St, P.O.Box: 7762, Riyadh, Saudi Arabia",
    },
    personalInfo: [
      {
        name: "Guy Hawkins",
        email: "user@email.com",
        position: "Senior General",
        img: "",
      },
      {
        name: "Esther Howard",
        email: "user@email.com",
        position: "Program Lead",
        img: "",
      },
      {
        name: "Theresa Webb",
        email: "user@email.com",
        position: "Senior HR",
        img: "",
      },
    ],
    location: {
      hall: 62,
      stand: 15,
      pavillion: 3,
    },
    assignedUsers: 2738,
    utilizedBadges: 700,
  },
  {
    companyInfo: {
      name: "Lockheed Martin",
      country: "US",
      icon: USMIco,
      email: "info@lockheedmartin.com",
      phone: "001 301-897-6000",
      location: "6801 Rockledge Dr, Bethesda, MD 20817, USA",
    },
    personalInfo: [
      {
        name: "John Doe",
        email: "john.doe@lockheedmartin.com",
        position: "Project Manager",
        img: "",
      },
    ],
    location: {
      hall: 12,
      stand: 5,
      pavillion: 1,
    },
    assignedUsers: 1500,
    utilizedBadges: 500,
  },
  {
    companyInfo: {
      name: "BAE Systems",
      country: "UK",
      icon: UKMIco,
      email: "info@baesystems.com",
      phone: "0044 1252 373232",
      location:
        "Warwick House, Farnborough Aerospace Centre, Farnborough, Hampshire, UK",
    },
    personalInfo: [
      {
        name: "Alice Johnson",
        email: "alice.johnson@baesystems.com",
        position: "Director",
        img: "",
      },
      {
        name: "Michael Wilson",
        email: "michael.wilson@baesystems.com",
        position: "Senior Engineer",
        img: "",
      },
    ],
    location: {
      hall: 22,
      stand: 10,
      pavillion: 2,
    },
    assignedUsers: 2000,
    utilizedBadges: 600,
  },
  {
    companyInfo: {
      name: "Rheinmetall AG",
      country: "Germany",
      icon: GermanyMIco,
      email: "info@rheinmetall.com",
      phone: "0049 211 473-01",
      location: "Rheinmetall Platz 1, 40476 Düsseldorf, Germany",
    },
    personalInfo: [
      {
        name: "Hans Müller",
        email: "hans.mueller@rheinmetall.com",
        position: "Chief Engineer",
        img: "",
      },
      {
        name: "Klara Schmidt",
        email: "klara.schmidt@rheinmetall.com",
        position: "Project Lead",
        img: "",
      },
      {
        name: "Peter Weber",
        email: "peter.weber@rheinmetall.com",
        position: "HR Director",
        img: "",
      },
    ],
    location: {
      hall: 32,
      stand: 20,
      pavillion: 4,
    },
    assignedUsers: 1800,
    utilizedBadges: 550,
  },
  {
    companyInfo: {
      name: "Bombardier",
      country: "Canada",
      icon: CanadaMIco,
      email: "info@bombardier.com",
      phone: "001 514-861-9481",
      location: "800 René-Lévesque Blvd. West, Montréal, Québec, Canada",
    },
    personalInfo: [
      {
        name: "James Lee",
        email: "james.lee@bombardier.com",
        position: "Senior Manager",
        img: "",
      },
      {
        name: "Sophia Martinez",
        email: "sophia.martinez@bombardier.com",
        position: "Lead Developer",
        img: "",
      },
      {
        name: "David Anderson",
        email: "david.anderson@bombardier.com",
        position: "HR Coordinator",
        img: "",
      },
    ],
    location: {
      hall: 42,
      stand: 25,
      pavillion: 5,
    },
    assignedUsers: 2200,
    utilizedBadges: 650,
  },
];

export default ExhibitorsTable;
