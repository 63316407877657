import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Divider, Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import VisitorComparisonPieChart from "../../components/charts/VisitorComparisonPieChart";

function Comparison() {
  return (
    <CustomCard w="100%" minH="330px">
      <Flex align="center" gap="16px">
        <CustomText
          text="KSA VS International"
          color="yess.base_black"
          wrap="nowrap"
        />
        <Divider bg="#F2F2F2" mt="5px" />
      </Flex>
      <Flex justify="space-between" mt="24px">
        <Flex
          justify="space-between"
          minH="250px"
          flexDir="column"
          maxW="175px"
        >
          <Flex gap="6px" flexDir="column">
            <CustomText text="Total Vistors" size="17px" />
            <CustomText text="36,392" size="32px" color="yess.neutral1000" />
          </Flex>
          <Flex gap="24px" flexDir="column">
            <Flex flexDir="column" gap="8px">
              <CustomText
                text="Kingdom of Saudi Arabia Visitors"
                size="12px"
                color="yess.neutral500"
              />
              <Flex align="center" gap="6px">
                <div>
                  <Box
                    minH="12px"
                    minW="12px"
                    bg="yess.secondary_blue"
                    mt="4px"
                  />
                </div>
                <CustomText text="1,256" size="14px" color="yess.neutral1000" />
              </Flex>
            </Flex>
            <Flex flexDir="column" gap="8px">
              <CustomText
                text="International Visitors"
                size="12px"
                color="yess.neutral500"
              />
              <Flex align="center" gap="6px">
                <CustomText text="232" size="14px" color="yess.neutral1000" />
                <div>
                  <Box
                    minH="12px"
                    minW="12px"
                    bg="yess.secondary_gold"
                    mt="4px"
                  />
                </div>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <VisitorComparisonPieChart />
      </Flex>
    </CustomCard>
  );
}

export default Comparison;
