import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import Ad1Img from "../../assets/images/digital-signage/ad-1.png";
import Ad2Img from "../../assets/images/digital-signage/ad-2.png";
import Ad3Img from "../../assets/images/digital-signage/ad-3.png";
import Ad4Img from "../../assets/images/digital-signage/ad-4.png";
import PencilEdit from "../../svgs/PencilEdit";

function AdScreens() {
  return (
    <Flex mt="16px" gap="16px" flexDir="column">
      <CustomText text="Ad Screens" color="yess.base_black" />
      <Flex gap="24px">
        {ADS.map((ad, index) => (
          <AD_CARD key={index} ad={ad} />
        ))}
      </Flex>
    </Flex>
  );
}

const AD_CARD = ({ ad }: any) => {
  return (
    <Flex
      flexDir="column"
      gap="24px"
      p="16px"
      borderRadius="12px"
      border="1px solid"
      borderColor="yess.secondary_light_gray"
    >
      <Box pos="relative">
        <Image
          src={ad?.img}
          alt="ad1"
          // maxW="242px"
          // maxH="160px"
          objectFit="cover"
        />
        <Flex
          align="center"
          justify="center"
          h="32px"
          w="32px"
          bg="rgba(255, 255, 255, 0.90)"
          borderRadius="8px"
          pos="absolute"
          top="0"
          right="0"
          mt="10px"
          mr="10px"
        >
          <PencilEdit />
        </Flex>
      </Box>
      <Flex flexDir="column" gap="12px">
        <CustomText text={ad?.title} color="yess.base_black" />
        <CustomText text={ad?.desc} color="yess.neutral500" size="14px" />
      </Flex>
    </Flex>
  );
};

const ADS = [
  {
    img: Ad1Img,
    title: "Ad #1",
    desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fug...",
  },
  {
    img: Ad2Img,
    title: "Ad #2",
    desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fug...",
  },
  {
    img: Ad3Img,
    title: "Ad #3",
    desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fug...",
  },
  {
    img: Ad4Img,
    title: "Ad #4",
    desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fug...",
  },
];

export default AdScreens;
