import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Flex, Image } from "@chakra-ui/react";
import CountriesIcon from "../../svgs/CountriesIcon";
import CustomText from "../../components/text/CustomText";
import SaudiFlag from "../../assets/images/flag-saudi.svg";
import QatarFlag from "../../assets/images/flag-qatar.svg";
import USFlag from "../../assets/images/flag-us.svg";
import UKFlag from "../../assets/images/flag-uk.svg";
import ChinaFlag from "../../assets/images/flag-china.svg";
import AustraliaFlag from "../../assets/images/flag-australia.svg";
import ReactMapChart from "../../components/charts/ReactMapChart";

function TopCountries() {
  return (
    <CustomCard w="100%">
      <Flex align="center" gap="16px">
        <Flex
          minH="40px"
          minW="40px"
          borderRadius="8px"
          bg="yess.secondary_light_gray"
          align="center"
          justify="center"
        >
          <CountriesIcon />
        </Flex>
        <Flex gap="4px" align="center">
          <CustomText text="Countries" color="yess.base_black" />
          <CustomText text="(Top 6)" color="yess.neutral400" />
        </Flex>
      </Flex>
      <Flex justify="space-between" align="center" gap="24px" mt="24px">
        <Box maxW="500px" w="100%">
          <Flex
            pb="8px"
            borderBottom="1px solid"
            borderColor="yess.border"
            align="center"
            justify="space-between"
          >
            <CustomText
              text="Total Visitors"
              size="17px"
              color="yess.neutral300"
            />
            <CustomText text="253,682" size="24px" color="yess.neutral1000" />
          </Flex>
          <Flex mt="16px" gap="12px" flexDir="column">
            {TOP_COUNTRIES.map((country, index) => (
              <Flex
                key={index}
                align="center"
                justify="space-between"
                gap="8px"
              >
                <Flex align="center" gap="8px">
                  <Image src={country.flag} boxSize="16px" />
                  <CustomText
                    text={country.name}
                    size="12px"
                    color="yess.base_black"
                  />
                </Flex>
                <Flex align="center" gap="8px">
                  <CustomText
                    text={country.users}
                    size="12px"
                    color="yess.neutral600"
                  />
                  <CustomText
                    text={country.percent}
                    size="12px"
                    color="yess.secondary_blue"
                  />
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Box>
        <Box w="1px" bg="yess.secondary_light_gray" minH="100%" />
        <Box mx="auto">
          <ReactMapChart />
        </Box>{" "}
      </Flex>
    </CustomCard>
  );
}

const TOP_COUNTRIES = [
  {
    name: "Saudia Arabia",
    users: "998,123",
    percent: "25%",
    flag: SaudiFlag,
  },
  {
    name: "Qatar",
    users: "8,123",
    percent: "8%",
    flag: QatarFlag,
  },
  {
    name: "United States",
    users: "23,638",
    percent: "17%",
    flag: USFlag,
  },
  {
    name: "United Kingdom",
    users: "927,392",
    percent: "25%",
    flag: UKFlag,
  },
  {
    name: "China",
    users: "3,929",
    percent: "11%",
    flag: ChinaFlag,
  },
  {
    name: "Australia",
    users: "8,123",
    percent: "14%",
    flag: AustraliaFlag,
  },
];

export default TopCountries;
