import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import CustomHeading from "../text/CustomHeading";
import ThemeIcon from "../../svgs/ThemeIcon";
import IconNotification from "../../svgs/IconNotification";
import AvatarIcon from "../../assets/images/avatar.svg";
import CustomText from "../text/CustomText";
import { IconChevronDown } from "@tabler/icons-react";

function Navbar() {
  return (
    <Flex
      align="center"
      justify="space-between"
      p="24px"
      borderBottom="1px solid"
      borderColor="yess.border"
      h="88px"
      justifyContent="space-between"
    >
      <CustomHeading
        text="Exhibitors Management Dashboard"
        family="aeonik-regular"
        size="20px"
      />
      <Flex align="center" gap="16px">
        <Flex
          h="40px"
          w="40px"
          borderRadius="8px"
          border="1px solid"
          borderColor="yess.border"
          align="center"
          justify="center"
        >
          <ThemeIcon />
        </Flex>
        <Flex
          h="40px"
          w="40px"
          borderRadius="8px"
          border="1px solid"
          borderColor="yess.border"
          align="center"
          justify="center"
        >
          <IconNotification />
        </Flex>
        <Flex gap="8px" align="center">
          <Image src={AvatarIcon} boxSize="40px" borderRadius="8px" />
          <Flex flexDir="column">
            <CustomText text="Omar Kareem" color="yess.base_black" />
            <CustomText
              text="omarkareem@email.com"
              color="yess.neutral500"
              size="14px"
            />
          </Flex>
          <IconChevronDown color="#292D32" size="20px" />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Navbar;
