import React from "react";

function BlueLine() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="14"
      viewBox="0 0 4 14"
      fill="none"
    >
      <path
        d="M2 2L2 12"
        stroke="#387DF5"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default BlueLine;
