import { Flex } from "@chakra-ui/react";
import React from "react";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import FormInput from "../../components/form/FormInput";

function AssignLocation() {
  return (
    <Flex
      flexDir="column"
      gap="24px"
      maxW="400px"
      w="100%"
      mx="auto"
      minH="521px"
    >
      <CustomHeading
        text="Assign Location"
        family="aeonik-bold"
        align="center"
      />
      <CustomText
        text="Assign location to exhibitor"
        align="center"
        color="yess.neutral600"
      />
      <Flex flexDir="column" gap="11px" w="100%">
        <CustomText text="Hall #" size="12px" />
        <FormInput ph="Enter Hall #" />
      </Flex>

      <Flex flexDir="column" gap="11px" w="100%">
        <CustomText text="Stand #" size="12px" />
        <FormInput ph="Enter Stand #" />
      </Flex>
      <Flex flexDir="column" gap="11px" w="100%">
        <CustomText text="Pavilion #" size="12px" />
        <FormInput ph="Enter Pavilion #" />
      </Flex>
    </Flex>
  );
}

export default AssignLocation;
