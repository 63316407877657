import { Flex } from "@chakra-ui/react";
import React from "react";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import FormInput from "../../components/form/FormInput";
import IconChevDown from "../../svgs/IconChevDown";
import CustomPhoneInput from "../../components/form/PhoneInput";

function CompanyInfo() {
  return (
    <Flex
      flexDir="column"
      gap="22px"
      maxW="666px"
      minH="521px"
      w="100%"
      mx="auto"
    >
      <CustomHeading text="Company Info" family="aeonik-bold" align="center" />
      <CustomText
        text="Enter Company Info of the exhibitor"
        align="center"
        color="yess.neutral600"
      />
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Company Name" size="12px" />
          <FormInput ph="Enter Company Name" maxW="322px" />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Job Title" size="12px" />
          <FormInput ph="Enter Job Title" maxW="322px" />
        </Flex>
      </Flex>
      <Flex flexDir="column" gap="11px" w="100%">
        <CustomText text="Company Email" size="12px" />
        <FormInput ph="Enter Email" type="email" />
      </Flex>
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Company Location" size="12px" />
          <FormInput
            ph="Select Company Location"
            maxW="322px"
            rightIco={<IconChevDown />}
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Company Phone Number" size="12px" />
          <CustomPhoneInput />
        </Flex>
      </Flex>
      <Flex flexDir="column" gap="11px" w="100%">
        <CustomText text="Address" size="12px" />
        <FormInput ph="Enter Address" />
      </Flex>
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="City" size="12px" />
          <FormInput
            ph="Select City"
            maxW="322px"
            rightIco={<IconChevDown />}
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Zip Code" size="12px" />
          <FormInput ph="Enter Zip Code" maxW="322px" />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CompanyInfo;
