import { Box, Divider, Flex } from "@chakra-ui/react";
import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import CustomText from "../../components/text/CustomText";
import BoothShopIcon from "../../svgs/BoothShopIcon";
import BgGradientLines from "../../assets/images/bg-lines.png";
import BgGradientLines2 from "../../assets/images/bg-lines2.png";
import GoldLine from "../../svgs/GoldLine";

function BoothStats() {
  return (
    <Flex gap="24px" align="center" mt="24px">
      <CustomCard maxW="306px">
        <Flex flexDir="column" justify="space-between" minH="152px">
          <Flex gap="16px" align="center">
            <Flex
              align="center"
              justify="center"
              h="40px"
              w="40px"
              bg={"yess.secondary_light_gray"}
              borderRadius="8px"
            >
              <BoothShopIcon />
            </Flex>
            <CustomText text="Total Booths" color="yess.base_black" />
          </Flex>
          <Flex gap="10px" align="center">
            <CustomText text="330" size="32px" color="yess.base_black" />
          </Flex>
          <CustomText
            text={
              <>
                <span
                  style={{
                    color: "#2EC19E",
                  }}
                >
                  2.07%
                </span>
                <span> than last month</span>
              </>
            }
            size="14px"
            color="yess.neutral700"
          />
        </Flex>
      </CustomCard>
      <CustomCard>
        <Flex flexDir="column" justify="space-between" minH="152px">
          <Flex gap="16px" align="center">
            <CustomText
              text="Booths Availability"
              wrap="nowrap"
              color="yess.base_black"
            />
            <Divider bg="#F2F2F2" />
          </Flex>

          <Flex gap="4px" align="center">
            <Flex flexDir="column" gap="3px" w="60%">
              <CustomText
                text="Occupied Booths"
                size="12px"
                color="yess.secondary_blue"
              />
              <CustomText text="190" size="19px" color="#1A1A1A" />
              <CustomText text="|" size="16px" color="#F2F2F2" />
              <Box
                borderRadius="6px"
                background={`url(${BgGradientLines2}), #387DF5`}
                bgRepeat="no-repeat"
                bgSize="cover"
                h="32px"
                w="100%"
              />
            </Flex>
            <Flex flexDir="column" gap="3px" w="40%">
              <CustomText
                text="Available Booths"
                size="12px"
                color="yess.secondary_gold"
              />
              <CustomText text="140" size="19px" color="#1A1A1A" />
              <CustomText text="|" size="16px" color="#F2F2F2" />
              <Box
                borderRadius="6px"
                background={`url(${BgGradientLines2}), #D79A2B`}
                bgRepeat="no-repeat"
                bgSize="cover"
                h="32px"
                w="100%"
              />
            </Flex>
          </Flex>
        </Flex>
      </CustomCard>
      <CustomCard maxW="306px">
        <Flex flexDir="column" justify="space-between" minH="152px">
          <Flex gap="16px" align="center">
            <CustomText
              text="Categories"
              wrap="nowrap"
              color="yess.base_black"
            />
            <Divider bg="#F2F2F2" />
          </Flex>

          <Flex flexDir="column" gap="8px">
            {CATEGORIES.map((category, index) => (
              <Flex key={index} gap="12px" align="flex-start">
                <Box maxW="47px" w="100%">
                  <CustomText
                    text={category.title}
                    color="yess.neutral700"
                    size="10px"
                    align="end"
                  />
                </Box>
                <Flex
                  w="167px"
                  h="24px"
                  borderRadius="4px"
                  background={`url(${BgGradientLines}), rgba(242, 242, 242, 0.75)`}
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                >
                  <Flex
                    p="4px"
                    bg="#e9e9e9"
                    w={category.value}
                    h="24px"
                    borderRadius="4px"
                    zIndex="10"
                  >
                    <Box bg="#2EC19E" borderRadius="4px" w="100%" />
                  </Flex>
                  <Box mt="5px" ml="-2px" zIndex="1">
                    <GoldLine />
                  </Box>
                </Flex>
                <Box maxW="22px" w="100%">
                  <CustomText
                    text={category.value}
                    color="yess.neutral700"
                    size="10px"
                  />
                </Box>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </CustomCard>
    </Flex>
  );
}

const CATEGORIES = [
  {
    title: "Standard",
    value: "35%",
  },
  {
    title: "Premium",
    value: "53%",
  },
  {
    title: "VIP",
    value: "22%",
  },
];

export default BoothStats;
