import {
  Avatar,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import EyeIcon from "../../svgs/EyeIcon";
import MailIcon from "../../svgs/MailIcon";
import EditIcon from "../../svgs/EditIcon";
import ArrowLeft from "../../svgs/ArrowLeft";
import ArrowRight from "../../svgs/ArrowRight";
import SaudiMIco from "../../assets/images/saudi-military.svg";
import USMIco from "../../assets/images/us-military.svg";
import UKMIco from "../../assets/images/uk-military.svg";
import GermanyMIco from "../../assets/images/germany-military.svg";
import CanadaMIco from "../../assets/images/canada-military.svg";

function BoothTable() {
  return (
    <Flex mt="16px" gap="20px" flexDir="column">
      <TableContainer overflowY="auto">
        <Table>
          <Thead>
            <Tr>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                borderTopLeftRadius="12px"
              >
                <CustomText
                  text="Booth ID"
                  size="12px"
                  align="center"
                  color="yess.neutral1000"
                />
              </Th>
              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Company"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Category"
                  size="12px"
                  color="yess.neutral1000"
                  align="center"
                />
              </Th>
              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Service Requests"
                  size="12px"
                  align="center"
                  color="yess.neutral1000"
                />
              </Th>

              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Status"
                  size="12px"
                  align="center"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                borderTopRightRadius="12px"
              >
                <CustomText
                  text="Action"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {BOOTHS?.map((exhi: any, idx: number) => (
              <Tr key={idx}>
                <Td borderLeft="1px solid #F2F2F2" p="16px 20px">
                  <CustomText
                    text={exhi.boothId}
                    size="14px"
                    align="center"
                    color="yess.neutral800"
                  />
                </Td>
                <Td borderLeft="1px solid #f2f2f2" p="6px 20px">
                  {exhi?.isAssigned ? (
                    <Flex gap="10px" py="10px" align="center">
                      <Avatar size="sm" src={exhi?.companyInfo?.icon} />
                      <Flex flexDir="column" gap="4px">
                        <CustomText
                          text={exhi?.companyInfo?.name}
                          color="yess.neutral1000"
                        />
                        <CustomText
                          text={exhi?.companyInfo?.country}
                          color="yess.neutral500"
                          size="12px"
                        />
                      </Flex>
                    </Flex>
                  ) : (
                    <CustomText text="Not Assigned" size="14px" />
                  )}
                </Td>
                <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                  <CustomText
                    text={exhi?.category}
                    size="14px"
                    color="yess.neutral800"
                    align="center"
                  />
                </Td>
                <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                  <CustomText
                    text={exhi?.serviceRequest}
                    size="14px"
                    color="yess.neutral800"
                    align="center"
                  />
                </Td>

                <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                  <Badge status={exhi?.status} />
                </Td>
                <Td
                  borderLeft="1px solid #f2f2f2"
                  borderRight="1px solid #f2f2f2"
                  p="16px 20px"
                >
                  <Flex align="center" gap="6px">
                    <ActionButton icon={<EyeIcon />} />
                    <ActionButton icon={<MailIcon />} />
                    <ActionButton
                      bg="yess.secondary_gold"
                      icon={<EditIcon />}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex align="center" gap="16px" justify="end">
        <CustomText text="1-10 of 52" size="13px" color="yess.neutral300" />
        <Flex align="center" gap="2px">
          <PaginationButton icon={<ArrowLeft />} />
          <PaginationButton text="1" />
          <PaginationButton text="2" bg="yess.secondary_gold" />
          <PaginationButton text="3" />
          <PaginationButton text="4" />
          <PaginationButton text="5" />
          <PaginationButton icon={<ArrowRight />} />
        </Flex>
      </Flex>
    </Flex>
  );
}

const ActionButton = ({ icon, bg }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="32px"
      w="32px"
      bg={bg || "yess.secondary_light_gray"}
      borderRadius="8px"
    >
      {icon}
    </Flex>
  );
};

const Badge = ({ status }: any) => {
  const getBorderColor = () => {
    switch (status) {
      case "Available":
        return "#FFFFFF";
      case "Occupied":
        return "#4E4949";
      default:
        return "yess.secondary_light_gray";
    }
  };

  const getBgColor = () => {
    switch (status) {
      case "Available":
        return "#2EC19E";
      case "Occupied":
        return "#E4E4E4";
      default:
        return "yess.base_black";
    }
  };

  return (
    <Flex
      align="center"
      justify="center"
      w="98px"
      h="30px"
      borderRadius="99px"
      bg={getBgColor()}
      mx="auto"
    >
      <CustomText text={status} size="12px" color={getBorderColor()} />
    </Flex>
  );
};

const PaginationButton = ({ text, bg, icon }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="30px"
      w="30px"
      bg={bg || "#fff"}
      borderRadius="6px"
    >
      {icon && icon}
      {text && (
        <CustomText
          text={text}
          size="14px"
          weight="500"
          color={bg ? "#fff" : "yess.neutral300"}
        />
      )}
    </Flex>
  );
};

const BOOTHS = [
  {
    boothId: 45329738,
    companyInfo: {
      name: "Saudi Arabian Military Industries",
      country: "Saudi Arabia",
      icon: SaudiMIco,
    },
    category: "VIP",
    serviceRequest: 2,
    status: "Occupied",
    isAssigned: true,
  },
  {
    boothId: 45329738,
    companyInfo: {
      name: "Fortis Tactical Solutions",
      country: "United States",
      icon: USMIco,
    },
    category: "Standard",
    serviceRequest: 2,
    status: "Available",
    isAssigned: true,
  },
  {
    boothId: 45329738,
    companyInfo: {
      name: "UK Military",
      country: "United Kingdom",
      icon: UKMIco,
    },
    category: "Premium",
    serviceRequest: 6,
    status: "Available",
    isAssigned: false,
  },
  {
    boothId: 45329738,
    companyInfo: {
      name: "Vanguard Defense Innovations",
      country: "Germany",
      icon: GermanyMIco,
    },
    category: "Premium",
    serviceRequest: 2,
    status: "Occupied",
    isAssigned: true,
  },
  {
    boothId: 45329738,
    companyInfo: {
      name: "Rampart Global Defense",
      country: "Canada",
      icon: CanadaMIco,
    },
    category: "VIP",
    serviceRequest: 2,
    status: "Occupied",
    isAssigned: true,
  },
  {
    boothId: 45329738,
    companyInfo: {
      name: "Rampart Global Defense",
      country: "Canada",
      icon: CanadaMIco,
    },
    category: "VIP",
    serviceRequest: 5,
    status: "Available",
    isAssigned: false,
  },
  {
    boothId: 45329738,
    companyInfo: {
      name: "Rampart Global Defense",
      country: "Canada",
      icon: CanadaMIco,
    },
    category: "Standard",
    serviceRequest: 4,
    status: "Occupied",
    isAssigned: true,
  },
];

export default BoothTable;
