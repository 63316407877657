import {
  Avatar,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import EyeIcon from "../../svgs/EyeIcon";
import MailIcon from "../../svgs/MailIcon";
import EditIcon from "../../svgs/EditIcon";
import ArrowLeft from "../../svgs/ArrowLeft";
import ArrowRight from "../../svgs/ArrowRight";

function BadgeTable() {
  return (
    <Flex mt="16px" gap="20px" flexDir="column">
      <TableContainer overflowY="auto">
        <Table>
          <Thead>
            <Tr>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                borderTopLeftRadius="12px"
              >
                <CustomText
                  text="Badge No"
                  size="12px"
                  align="center"
                  color="yess.neutral1000"
                />
              </Th>
              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Attendee Info"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Type"
                  size="12px"
                  color="yess.neutral1000"
                  align="center"
                />
              </Th>
              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Issue Date"
                  size="12px"
                  align="center"
                  color="yess.neutral1000"
                />
              </Th>
              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Valid for"
                  size="12px"
                  align="center"
                  color="yess.neutral1000"
                />
              </Th>
              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Status"
                  size="12px"
                  align="center"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                borderTopRightRadius="12px"
              >
                <CustomText
                  text="Action"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {BAGES?.map((exhi: any, idx: number) => (
              <Tr key={idx}>
                <Td borderLeft="1px solid #F2F2F2" p="16px 20px">
                  <CustomText
                    text={exhi.badgeNo}
                    size="14px"
                    align="center"
                    color="yess.neutral800"
                  />
                </Td>
                <Td borderLeft="1px solid #f2f2f2" p="6px 20px">
                  <Flex gap="10px" py="10px" align="center">
                    <Avatar size="sm" />
                    <Flex flexDir="column" gap="4px">
                      <CustomText
                        text={exhi?.personalInfo?.name}
                        color="yess.neutral1000"
                      />
                      <CustomText
                        text={exhi?.personalInfo?.email}
                        color="yess.neutral500"
                        size="12px"
                      />
                    </Flex>
                  </Flex>
                </Td>
                <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                  <CustomText
                    text={exhi?.type}
                    size="14px"
                    color="yess.neutral800"
                    align="center"
                  />
                </Td>
                <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                  <CustomText
                    text={exhi?.issueDate}
                    size="14px"
                    color="yess.neutral800"
                    align="center"
                  />
                </Td>
                <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                  <CustomText
                    text={exhi?.validFor}
                    size="14px"
                    color="yess.neutral800"
                    align="center"
                  />
                </Td>
                <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                  <Badge status={exhi?.status} />
                </Td>
                <Td
                  borderLeft="1px solid #f2f2f2"
                  borderRight="1px solid #f2f2f2"
                  p="16px 20px"
                >
                  <Flex align="center" gap="6px">
                    <ActionButton icon={<EyeIcon />} />
                    <ActionButton icon={<MailIcon />} />
                    <ActionButton
                      bg="yess.secondary_gold"
                      icon={<EditIcon />}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex align="center" gap="16px" justify="end">
        <CustomText text="1-10 of 52" size="13px" color="yess.neutral300" />
        <Flex align="center" gap="2px">
          <PaginationButton icon={<ArrowLeft />} />
          <PaginationButton text="1" />
          <PaginationButton text="2" bg="yess.secondary_gold" />
          <PaginationButton text="3" />
          <PaginationButton text="4" />
          <PaginationButton text="5" />
          <PaginationButton icon={<ArrowRight />} />
        </Flex>
      </Flex>
    </Flex>
  );
}

const ActionButton = ({ icon, bg }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="32px"
      w="32px"
      bg={bg || "yess.secondary_light_gray"}
      borderRadius="8px"
    >
      {icon}
    </Flex>
  );
};

const Badge = ({ status }: any) => {
  const getBgColor = () => {
    switch (status) {
      case "Active":
        return "#F1FFFC";
      case "Expired":
        return "#FFE9EB";
      case "Pending":
        return "#FFF9F1";
      default:
        return "yess.secondary_light_gray";
    }
  };

  const getBorderColor = () => {
    switch (status) {
      case "Active":
        return "#2EC19E";
      case "Expired":
        return "#ED3D4E";
      case "Pending":
        return "#EDA145";
      default:
        return "yess.base_black";
    }
  };

  return (
    <Flex
      align="center"
      justify="center"
      w="98px"
      h="30px"
      borderRadius="99px"
      border="1px solid"
      borderColor={getBorderColor()}
      bg={getBgColor()}
      mx="auto"
    >
      <CustomText text={status} size="12px" color={getBorderColor()} />
    </Flex>
  );
};

const PaginationButton = ({ text, bg, icon }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="30px"
      w="30px"
      bg={bg || "#fff"}
      borderRadius="6px"
    >
      {icon && icon}
      {text && (
        <CustomText
          text={text}
          size="14px"
          weight="500"
          color={bg ? "#fff" : "yess.neutral300"}
        />
      )}
    </Flex>
  );
};

const BAGES = [
  {
    badgeNo: 45329738,
    personalInfo: {
      name: "Guy Hawkins",
      email: "user@email.com",
      position: "Senior General",
      img: "",
    },
    type: "Exhibitor",
    issueDate: "Sep 16, 2024",
    validFor: "All days",
    status: "Active",
  },
  {
    badgeNo: 45329739,
    personalInfo: {
      name: "Jane Doe",
      email: "jane.doe@email.com",
      position: "Marketing Manager",
      img: "",
    },
    type: "Contractor",
    issueDate: "Sep 5, 2024",
    validFor: "Before & After Event",
    status: "Expired",
  },
  {
    badgeNo: 45329740,
    personalInfo: {
      name: "John Smith",
      email: "john.smith@email.com",
      position: "Sales Executive",
      img: "",
    },
    type: "Support Staff",
    issueDate: "--",
    validFor: "--",
    status: "Pending",
  },
  {
    badgeNo: 45329741,
    personalInfo: {
      name: "Alice Johnson",
      email: "alice.johnson@email.com",
      position: "Product Manager",
      img: "",
    },
    type: "Visitor",
    issueDate: "Sep 19, 2024",
    validFor: "3rd & 4th day",
    status: "Active",
  },
  {
    badgeNo: 45329742,
    personalInfo: {
      name: "Bob Brown",
      email: "bob.brown@email.com",
      position: "Technical Lead",
      img: "",
    },
    type: "Media",
    issueDate: "Sep 20, 2024",
    validFor: "All days",
    status: "Active",
  },
  {
    badgeNo: 45329743,
    personalInfo: {
      name: "Charlie Davis",
      email: "charlie.davis@email.com",
      position: "HR Manager",
      img: "",
    },
    type: "Delegation",
    issueDate: "Sep 21, 2024",
    validFor: "2nd day",
    status: "Active",
  },
  {
    badgeNo: 45329744,
    personalInfo: {
      name: "Diana Evans",
      email: "diana.evans@email.com",
      position: "CEO",
      img: "",
    },
    type: "VIP",
    issueDate: "Sep 22, 2024",
    validFor: "1st days",
    status: "Expired",
  },
];

export default BadgeTable;
