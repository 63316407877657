import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import {
  Box,
  Button,
  Checkbox,
  Circle,
  Flex,
  Image,
  Textarea,
} from "@chakra-ui/react";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import CustomText from "../../components/text/CustomText";
import JohnDoe from "../../assets/images/john-doe.png";
import Passport from "../../assets/images/passport.png";
import IqamaFront from "../../assets/images/iqama-front.png";
import IqamaBack from "../../assets/images/iqama-back.png";
import FilledCircle from "../../assets/images/filled-circle.svg";
import { useNavigate } from "react-router-dom";

function VisitorDetails() {
  const navigate = useNavigate();
  return (
    <CustomCard>
      <Flex flexDir="column" gap="24px">
        <Flex align="center" gap="16px">
          <IconArrowLeft
            size={24}
            color="#387DF5"
            cursor="pointer"
            onClick={() => {
              navigate("/visitors");
            }}
          />
          <CustomText
            text="Tarek Kareem Vetting Profile Detail"
            size="18px"
            color="yess.base_black"
          />
        </Flex>
        <Flex gap="24px">
          <Flex flexDir="column" w="100%">
            <Flex align="center" justify="space-between">
              <Image src={JohnDoe} alt="john" boxSize="134px" />
              <Flex gap="10px" align="center">
                <CustomText
                  text="Real Image"
                  color="yess.neutral500"
                  mt="-5px"
                />
                <Checkbox size="lg" />
              </Flex>
            </Flex>
            <CustomText
              text="Personal Info"
              color="yess.neutral500"
              mt="16px"
            />
            <Flex
              mt="8px"
              flexDir="column"
              gap="16px"
              p="16px"
              borderRadius="12px"
              border="1px solid"
              borderColor="yess.secondary_light_gray"
            >
              <Flex align="center" justify="space-between">
                <CustomText text="Name" color="yess.neutral500" />
                <Flex align="center" gap="40px">
                  <CustomText text="Tarek Kareem" color="yess.neutral800" />
                  <Checkbox size="lg" isChecked />
                </Flex>
              </Flex>
              <Flex align="center" justify="space-between">
                <CustomText text="Nationality" color="yess.neutral500" />
                <Flex align="center" gap="40px">
                  <CustomText text="Saudi Arabia" color="yess.neutral800" />
                  <Checkbox size="lg" />
                </Flex>
              </Flex>
              <Flex align="center" justify="space-between">
                <CustomText text="DOB" color="yess.neutral500" />
                <Flex align="center" gap="40px">
                  <CustomText text="02 Oct, 1998" color="yess.neutral800" />
                  <Checkbox size="lg" />
                </Flex>
              </Flex>
              <Flex align="center" justify="space-between">
                <CustomText text="Address" color="yess.neutral500" />
                <Flex align="center" gap="40px">
                  <CustomText
                    text="Mecca Saudi Arabia"
                    color="yess.neutral800"
                  />
                  <Checkbox size="lg" />
                </Flex>
              </Flex>
            </Flex>
            <CustomText
              text="Uploaded Documents"
              color="yess.neutral500"
              mt="16px"
            />
            <Flex
              mt="8px"
              gap="24px"
              p="16px"
              borderRadius="12px"
              border="1px solid"
              borderColor="yess.secondary_light_gray"
            >
              <Flex flexDir="column" gap="12px">
                <Image
                  src={Passport}
                  alt="passport"
                  height="134px"
                  borderRadius="8px"
                />
                <Flex justify="space-between" gap="16px" align="center">
                  <CustomText
                    text="Passport"
                    size="14px"
                    color="yess.neutral1000"
                  />
                  <Checkbox size="lg" isChecked />
                </Flex>
              </Flex>
              <Flex flexDir="column" gap="12px">
                <Image
                  src={IqamaFront}
                  alt="iqama"
                  height="134px"
                  borderRadius="8px"
                />
                <Flex justify="space-between" gap="16px" align="center">
                  <CustomText
                    text={
                      <>
                        <span>Iqama</span>
                        <span style={{ color: "#BAB8B8" }}> (Front)</span>
                      </>
                    }
                    size="14px"
                    color="yess.neutral1000"
                  />
                  <Checkbox size="lg" />
                </Flex>
              </Flex>
              <Flex flexDir="column" gap="12px">
                <Image
                  src={IqamaBack}
                  alt="iqama"
                  height="134px"
                  borderRadius="8px"
                />
                <Flex justify="space-between" gap="16px" align="center">
                  <CustomText
                    text={
                      <>
                        <span>Iqama</span>
                        <span style={{ color: "#BAB8B8" }}> (Back)</span>
                      </>
                    }
                    size="14px"
                    color="yess.neutral1000"
                  />
                  <Checkbox size="lg" />
                </Flex>
              </Flex>
            </Flex>
            <CustomText text="Your Remarks" color="yess.neutral500" mt="16px" />
            <Textarea
              mt="8px"
              p="16px"
              borderRadius="8px"
              border="1px solid #F2F2F2"
              placeholder="Type here..."
              fontSize="14px"
            />
            <Flex mt="16px" gap="16px">
              <Button
                h="48px"
                w="120px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="12px"
                bg="#2EC19E"
                fontSize="14px"
                fontWeight="400"
                color="#fff"
                _hover={{
                  bg: "#2EC19E",
                }}
              >
                Approve
              </Button>
              <Button
                h="48px"
                w="120px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="12px"
                bg="#ED3D4E"
                fontSize="14px"
                fontWeight="400"
                color="#fff"
                _hover={{
                  bg: "#ED3D4E",
                }}
              >
                Reject
              </Button>
            </Flex>
          </Flex>
          <Box w="1px" bg="#f2f2f2" />
          <Flex flexDir="column" gap="32px" w="100%">
            <CustomText
              text="Vetting History"
              size="16px"
              weight="700"
              color="yess.neutral700"
            />
            <Flex gap="16px">
              <Flex flexDir="column" align="center">
                <Image src={FilledCircle} alt="filled" zIndex="10" />
                <Box
                  bg="yess.secondary_gold"
                  h="120px"
                  w="1px"
                  mt="-10px"
                  zIndex="1"
                />
                <Image src={FilledCircle} alt="filled" zIndex="10" />
                <Box
                  bg="yess.secondary_gold"
                  h="180px"
                  w="1px"
                  mt="-10px"
                  zIndex="1"
                />
                <Image src={FilledCircle} alt="filled" zIndex="10" />
                <Box
                  h="120px"
                  border="1px dashed"
                  borderColor="#C1C2C4"
                  mt="-10px"
                  zIndex="1"
                />
                <Circle size="20px" bg="#E4E4E4" />
              </Flex>
              <Flex flexDir="column">
                <Flex flexDir="column" gap="8px">
                  <CustomText
                    text="Oct 1, 2024 10:00 AM"
                    size="12px"
                    color="yess.secondary_gold"
                  />
                  <CustomText
                    text="User Action"
                    size="16px"
                    color="yess.neutral700"
                  />
                  <CustomText
                    text="Personal info updated by the user."
                    size="14px"
                    color="yess.neutral500"
                  />
                </Flex>
                <Flex flexDir="column" gap="8px" mt="60px">
                  <CustomText
                    text="Oct 2, 2024 08:00 AM"
                    size="12px"
                    color="yess.secondary_gold"
                  />
                  <Flex gap="10px" align="center">
                    <CustomText
                      text="Admin Action"
                      size="16px"
                      color="yess.neutral700"
                    />
                    <Circle size="4px" bg="#387DF5" />
                    <CustomText
                      text="Admin: John Smith"
                      size="12px"
                      color="yess.secondary_blue"
                    />
                  </Flex>
                  <CustomText
                    text="Reviewed submitted personal information and verified the user data except documents."
                    size="14px"
                    color="yess.neutral500"
                  />
                  <Flex p="16px" borderRadius="8px" bg="#f2f2f2" w="231px">
                    <CustomText
                      text="Image is blurry, unable to verify."
                      size="14px"
                      color="yess.neutral700"
                    />
                  </Flex>
                </Flex>
                <Flex flexDir="column" gap="8px" mt="45px">
                  <CustomText
                    text="Oct 3, 2024 10:00 AM"
                    size="12px"
                    color="yess.secondary_gold"
                  />
                  <Flex gap="10px" align="center">
                    <CustomText
                      text="User Action"
                      size="16px"
                      color="yess.neutral700"
                    />
                    <Button
                      h="28px"
                      w="102px"
                      display="flex"
                      gap="8px"
                      justifyContent="center"
                      alignItems="center"
                      bg="#2EC19E"
                      borderRadius="8px"
                      fontSize="12px"
                      fontWeight="400"
                      color="#fff"
                      _hover={{
                        bg: "#2EC19E",
                      }}
                    >
                      Completed
                      <Box>
                        <IconCheck color="#fff" size="14px" />
                      </Box>
                    </Button>
                  </Flex>
                  <CustomText
                    text="Re-uploaded a clearer selfie image"
                    size="14px"
                    color="yess.neutral500"
                  />
                </Flex>
                <Flex flexDir="column" gap="8px" mt="50px">
                  <CustomText
                    text="Oct 2, 2024 08:00 AM"
                    size="12px"
                    color="yess.secondary_gold"
                  />
                  <Flex gap="10px" align="center">
                    <CustomText
                      text="Admin Action"
                      size="16px"
                      color="yess.neutral700"
                    />
                    <Circle size="4px" bg="#387DF5" />
                    <CustomText
                      text="Admin: John Smith"
                      size="12px"
                      color="yess.secondary_blue"
                    />
                    <Button
                      h="28px"
                      w="102px"
                      display="flex"
                      gap="8px"
                      justifyContent="center"
                      alignItems="center"
                      bg="#ED3D4E"
                      borderRadius="8px"
                      fontSize="12px"
                      fontWeight="400"
                      color="#fff"
                      _hover={{
                        bg: "#ED3D4E",
                      }}
                    >
                      Rejected
                    </Button>
                  </Flex>
                  <CustomText
                    text="Reviewed submitted personal information."
                    size="14px"
                    color="yess.neutral500"
                  />
                  <Flex p="16px" borderRadius="8px" bg="#f2f2f2" w="400px">
                    <CustomText
                      text="The image is not verified. Kindly upload your actual clear image."
                      size="14px"
                      color="yess.neutral700"
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </CustomCard>
  );
}

export default VisitorDetails;
