import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import Info1Img from "../../assets/images/digital-signage/info-1.png";
import Info2Img from "../../assets/images/digital-signage/info-2.png";
import Info3Img from "../../assets/images/digital-signage/info-3.png";
import Info4Img from "../../assets/images/digital-signage/info-4.png";
import PencilEdit from "../../svgs/PencilEdit";

function InfoScreens() {
  return (
    <Flex mt="16px" gap="16px" flexDir="column">
      <CustomText text="Info Screens" color="yess.base_black" />
      <Flex gap="24px">
        {INFOS.map((info, index) => (
          <INFO_CARD key={index} info={info} />
        ))}
      </Flex>
    </Flex>
  );
}

const INFO_CARD = ({ info }: any) => {
  return (
    <Flex
      flexDir="column"
      gap="24px"
      p="16px"
      borderRadius="12px"
      border="1px solid"
      borderColor="yess.secondary_light_gray"
    >
      <Box pos="relative">
        <Image
          src={info?.img}
          alt="info"
          // maxW="242px"
          // maxH="160px"
          objectFit="cover"
        />
        <Flex
          align="center"
          justify="center"
          h="32px"
          w="32px"
          bg="rgba(255, 255, 255, 0.90)"
          borderRadius="8px"
          pos="absolute"
          top="0"
          right="0"
          mt="10px"
          mr="10px"
        >
          <PencilEdit />
        </Flex>
      </Box>
      <Flex flexDir="column" gap="12px">
        <CustomText text={info?.title} color="yess.base_black" />
        <CustomText text={info?.desc} color="yess.neutral500" size="14px" />
      </Flex>
    </Flex>
  );
};

const INFOS = [
  {
    img: Info1Img,
    title: "Info #1",
    desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fug...",
  },
  {
    img: Info2Img,
    title: "Info #2",
    desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fug...",
  },
  {
    img: Info3Img,
    title: "Info #3",
    desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fug...",
  },
  {
    img: Info4Img,
    title: "Info #4",
    desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fug...",
  },
];

export default InfoScreens;
