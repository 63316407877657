import React from "react";

function DigitalSignageIcon({ color }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.00016 14.6663C11.6821 14.6663 14.6668 11.6816 14.6668 7.99967C14.6668 4.31778 11.6821 1.33301 8.00016 1.33301C4.31826 1.33301 1.3335 4.31778 1.3335 7.99967C1.3335 11.6816 4.31826 14.6663 8.00016 14.6663Z"
        stroke={color || "#655F5F"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.9999 9.91992C7.39323 9.91992 6.8999 9.42657 6.8999 8.81991V7.17326C6.8999 6.56659 7.39323 6.07324 7.9999 6.07324C8.60656 6.07324 9.09989 6.56659 9.09989 7.17326V8.81991C9.09989 9.42657 8.60656 9.91992 7.9999 9.91992Z"
        stroke={color || "#655F5F"}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M11.3198 8.97978C11.1865 10.6998 9.7465 12.0465 7.99984 12.0465C6.15984 12.0465 4.6665 10.5531 4.6665 8.71314V7.28646C4.6665 5.44646 6.15984 3.95312 7.99984 3.95312C9.7265 3.95312 11.1465 5.26645 11.3132 6.94645"
        stroke={color || "#655F5F"}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default DigitalSignageIcon;
