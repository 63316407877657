import React from "react";

function GalleryImg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
    >
      <path
        d="M21.0001 51.3337H35.0001C46.6668 51.3337 51.3334 46.667 51.3334 35.0003V21.0003C51.3334 9.33366 46.6668 4.66699 35.0001 4.66699H21.0001C9.33342 4.66699 4.66675 9.33366 4.66675 21.0003V35.0003C4.66675 46.667 9.33342 51.3337 21.0001 51.3337Z"
        stroke="#C1C2C4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.9999 23.3333C23.5772 23.3333 25.6666 21.244 25.6666 18.6667C25.6666 16.0893 23.5772 14 20.9999 14C18.4226 14 16.3333 16.0893 16.3333 18.6667C16.3333 21.244 18.4226 23.3333 20.9999 23.3333Z"
        stroke="#C1C2C4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.22974 44.2166L17.7331 36.4933C19.5764 35.2566 22.2364 35.3966 23.8931 36.82L24.6631 37.4966C26.4831 39.06 29.4231 39.06 31.2431 37.4966L40.9497 29.1666C42.7697 27.6033 45.7097 27.6033 47.5297 29.1666L51.3331 32.4333"
        stroke="#C1C2C4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default GalleryImg;
