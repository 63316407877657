import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Divider, Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import { IconChevronDown } from "@tabler/icons-react";
import VisitorLineChart from "../../components/charts/VisitorLineChart";

function VisitorByType() {
  return (
    <CustomCard maxW="737px" w="100%">
      <Flex align="center" gap="16px">
        <CustomText
          text="Visitors By Type"
          color="yess.base_black"
          wrap="nowrap"
        />
        <Divider bg="#F2F2F2" mt="5px" />
        <Box pos="relative">
          <Flex
            justify="space-between"
            align="center"
            w="100px"
            h="32px"
            bg="yess.secondary_light_gray"
            borderRadius="8px"
            ps="12px"
            pe="6px"
            cursor="pointer"
          >
            <CustomText
              text="Today"
              size="12px"
              color="yess.neutral600"
              cursor
            />
            <IconChevronDown color="#0B0A0A" size="16px" cursor="pointer" />
          </Flex>
        </Box>
      </Flex>
      <Box mt="24px">
        <VisitorLineChart />
      </Box>
    </CustomCard>
  );
}

export default VisitorByType;
