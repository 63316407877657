import React from "react";

function DashboardIcon({ color }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M1 13.8C1 12.568 1 11.952 1.2776 11.4992C1.4328 11.2456 1.6456 11.0328 1.8992 10.8776C2.3512 10.6 2.968 10.6 4.2 10.6C5.432 10.6 6.048 10.6 6.5008 10.8776C6.7544 11.0328 6.9672 11.2456 7.1224 11.4992C7.4 11.9512 7.4 12.568 7.4 13.8C7.4 15.032 7.4 15.648 7.1224 16.1016C6.9672 16.3544 6.7544 16.5672 6.5008 16.7224C6.0488 17 5.432 17 4.2 17C2.968 17 2.352 17 1.8992 16.7224C1.64588 16.5675 1.43286 16.3547 1.2776 16.1016C1 15.648 1 15.032 1 13.8ZM10.6 13.8C10.6 12.568 10.6 11.952 10.8776 11.4992C11.0328 11.2456 11.2456 11.0328 11.4992 10.8776C11.9512 10.6 12.568 10.6 13.8 10.6C15.032 10.6 15.648 10.6 16.1016 10.8776C16.3544 11.0328 16.5672 11.2456 16.7224 11.4992C17 11.9512 17 12.568 17 13.8C17 15.032 17 15.648 16.7224 16.1016C16.5671 16.3544 16.3544 16.5671 16.1016 16.7224C15.648 17 15.032 17 13.8 17C12.568 17 11.952 17 11.4992 16.7224C11.2459 16.5675 11.0329 16.3547 10.8776 16.1016C10.6 15.648 10.6 15.032 10.6 13.8ZM1 4.2C1 2.968 1 2.352 1.2776 1.8992C1.4328 1.6456 1.6456 1.4328 1.8992 1.2776C2.3512 1 2.968 1 4.2 1C5.432 1 6.048 1 6.5008 1.2776C6.7544 1.4328 6.9672 1.6456 7.1224 1.8992C7.4 2.3512 7.4 2.968 7.4 4.2C7.4 5.432 7.4 6.048 7.1224 6.5008C6.9672 6.7544 6.7544 6.9672 6.5008 7.1224C6.0488 7.4 5.432 7.4 4.2 7.4C2.968 7.4 2.352 7.4 1.8992 7.1224C1.64579 6.96723 1.43276 6.75421 1.2776 6.5008C1 6.0488 1 5.432 1 4.2ZM10.6 4.2C10.6 2.968 10.6 2.352 10.8776 1.8992C11.0328 1.6456 11.2456 1.4328 11.4992 1.2776C11.9512 1 12.568 1 13.8 1C15.032 1 15.648 1 16.1016 1.2776C16.3544 1.4328 16.5672 1.6456 16.7224 1.8992C17 2.3512 17 2.968 17 4.2C17 5.432 17 6.048 16.7224 6.5008C16.5672 6.7544 16.3544 6.9672 16.1016 7.1224C15.648 7.4 15.032 7.4 13.8 7.4C12.568 7.4 11.952 7.4 11.4992 7.1224C11.2458 6.96723 11.0328 6.75421 10.8776 6.5008C10.6 6.0488 10.6 5.432 10.6 4.2Z"
        stroke={color || "#4E4949"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default DashboardIcon;
