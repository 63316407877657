import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import SingleUserIcon from "../../svgs/SingleUserIcon";
import GoldLine from "../../svgs/GoldLine";
import BgGradientLines from "../../assets/images/bg-lines.png";

function AssignedUsers() {
  return (
    <CustomCard>
      <Flex flexDir="column" justify="space-between" minH="282px">
        <Flex flexDir="column" gap="24px">
          <Flex gap="16px" align="center">
            <Flex
              align="center"
              justify="center"
              h="40px"
              w="40px"
              bg={"yess.secondary_light_gray"}
              borderRadius="8px"
            >
              <SingleUserIcon />
            </Flex>
            <CustomText
              text="Assigned Users"
              color="yess.base_black"
              size="18px"
            />
          </Flex>
          <Flex gap="10px" align="center">
            <CustomText text="8,654" size="32px" color="yess.base_black" />
            <Badge text="-10% this week" bg="#FDE7E9" color="#ED3D4E" />
          </Flex>
        </Flex>
        <Flex flexDir="row" gap="8px" mt="24px">
          {WEEK_DAYS.map((day, index) => (
            <Flex
              key={index}
              gap="12px"
              align="center"
              h="155px"
              flexDir="column"
            >
              <Flex
                h="155px"
                w="28px"
                borderRadius="4px"
                background={`url(${BgGradientLines}), rgba(242, 242, 242, 0.75)`}
                // backgroundRepeat="no-repeat"
                backgroundSize="cover"
                // backgroundPosition="left"
                flexDir="column"
                justify="end"
              >
                <Box zIndex="1" mb="-19px" transform="rotate(90deg)">
                  <GoldLine />
                </Box>
                <Flex
                  p="4px"
                  bg="#e9e9e9"
                  h={day.value}
                  w="28px"
                  borderRadius="4px"
                  zIndex="10"
                >
                  <Box bg="#387DF5" borderRadius="4px" w="100%" />
                </Flex>
              </Flex>
              <CustomText
                text={day.title}
                color="yess.neutral700"
                size="10px"
                align="center"
              />
            </Flex>
          ))}
        </Flex>
      </Flex>
    </CustomCard>
  );
}

const Badge = ({ bg, color, text }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      px="12px"
      py="4px"
      borderRadius="99px"
      bg={bg || "#D5FFF5"}
    >
      <CustomText text={text} size="12px" color={color || "#2EC19E"} />
    </Flex>
  );
};

const WEEK_DAYS = [
  {
    title: "Mon",
    value: "27%",
  },
  {
    title: "Tue",
    value: "73%",
  },
  {
    title: "Wed",
    value: "54%",
  },
  {
    title: "Thu",
    value: "24%",
  },
  {
    title: "Fri",
    value: "54%",
  },
  {
    title: "Sat",
    value: "54%",
  },
  {
    title: "Sun",
    value: "54%",
  },
];

export default AssignedUsers;
