import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import CustomInput from "../../components/form/CustomInput";
import { IconEyeOff } from "@tabler/icons-react";
import CustomButton from "../../components/buttons/CustomButton";
import AuthHeroImg from "../../assets/images/auth-hero.svg";
import Logo from "../../assets/images/Logo.svg";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  return (
    <Flex
      align="center"
      justify="space-between"
      minH="100vh"
      py="50px"
      px="150px"
      gap="50px"
    >
      <Flex
        flexDir="column"
        gap="40px"
        borderRadius="24px"
        border="1px solid"
        borderColor="yess.border"
        bg="yess.g1"
        p="56px 40px"
        maxW="480px"
        w="100%"
      >
        <Flex flexDir="column">
          <CustomHeading text="CMS Portal Login" />
          <CustomText text="Login to your account!" color="yess.neutral600" />
        </Flex>
        <Flex gap="22px" flexDir="column">
          <Flex flexDir="column">
            <CustomText text="Email" size="12px" />
            <CustomInput type="email" ph="Enter your email" />
          </Flex>
          <Flex flexDir="column">
            <CustomText text="Password" size="12px" />
            <CustomInput
              type="password"
              ph="Enter your password"
              rightIco={<IconEyeOff size="14px" color="#354052" />}
            />
          </Flex>
          <Box mt="10px">
            <CustomButton
              btnTitle="Login"
              bg="yess.secondary_gold"
              onClick={() => {
                navigate("/auth/create-event");
              }}
            />
          </Box>
        </Flex>
      </Flex>
      <Flex flexDir="column">
        <Image src={AuthHeroImg} alt="Auth Hero Image" maxH="80vh" />
        <Image src={Logo} alt="Logo" w="184px" h="87px" ms="auto" mt="-50px" />
      </Flex>
    </Flex>
  );
}

export default Login;
