import React from "react";

function CarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.3398 1.88672H5.65984C3.99984 1.88672 3.63317 2.71339 3.41984 3.72672L2.6665 7.33339H13.3332L12.5798 3.72672C12.3665 2.71339 11.9998 1.88672 10.3398 1.88672Z"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6598 13.213C14.7332 13.993 14.1065 14.6663 13.3065 14.6663H12.0532C11.3332 14.6663 11.2332 14.3597 11.1065 13.9797L10.9732 13.5797C10.7865 13.033 10.6665 12.6663 9.70649 12.6663H6.29316C5.33316 12.6663 5.19316 13.0797 5.02649 13.5797L4.89316 13.9797C4.76649 14.3597 4.66649 14.6663 3.94649 14.6663H2.69316C1.89316 14.6663 1.26649 13.993 1.33982 13.213L1.71316 9.15301C1.80649 8.15301 1.99982 7.33301 3.74649 7.33301H12.2532C13.9998 7.33301 14.1932 8.15301 14.2865 9.15301L14.6598 13.213Z"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.66667 5.33301H2"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0002 5.33301H13.3335"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 2V3.33333"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 3.33301H9"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 10H6"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 10H12"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CarIcon;
