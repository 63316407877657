import React, { useState } from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Divider, Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import CompanyInfo from "./CompanyInfo";
import CustomButton from "../../components/buttons/CustomButton";
import PersonalInfo from "./PersonalInfo";
import AssignLocation from "./AssignLocation";
import Documents from "./Documents";
import SendInvite from "./SendInvite";
import Congrats from "./Congrats";
import { useNavigate } from "react-router-dom";

function CreateExhibitor() {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(1);

  const checkActiveStep = (index: number) => {
    return activeStep === index + 1 || activeStep > index;
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 1:
        return <CompanyInfo />;
      case 2:
        return <PersonalInfo />;
      case 3:
        return <AssignLocation />;
      case 4:
        return <Documents />;
      case 5:
        return <SendInvite />;
      case 6:
        return <Congrats />;
      default:
        return <CompanyInfo />;
    }
  };

  return (
    <CustomCard>
      <Flex flexDir="column" gap="35px">
        <CustomText
          text="Create Exhibitors"
          size="24px"
          weight="700"
          color="yess.base_black"
        />
        <Flex align="center">
          {STEPS.map((step, index) => (
            <Flex align="center" gap="16px" key={index}>
              <Flex align="center" gap="12px">
                <Flex
                  align="center"
                  justify="center"
                  minH="32px"
                  minW="32px"
                  borderRadius="999px"
                  bg={
                    checkActiveStep(index) ? "yess.secondary_gold" : "#221F1F"
                  }
                >
                  <CustomText
                    text={index + 1}
                    size="12px"
                    color={
                      checkActiveStep(index)
                        ? "yess.base_black"
                        : "yess.neutral300"
                    }
                  />
                </Flex>
                <CustomText
                  text={step.title}
                  wrap="nowrap"
                  color={
                    checkActiveStep(index)
                      ? "yess.base_black"
                      : "yess.neutral600"
                  }
                />
              </Flex>
              <Divider
                maxW="110px"
                minW="80px"
                opacity="1"
                h="1px"
                border="none"
                display={index === STEPS.length - 1 ? "none" : "block"}
                me="16px"
                bg={
                  checkActiveStep(index)
                    ? "yess.secondary_gold"
                    : "yess.neutral600"
                }
              />
            </Flex>
          ))}
        </Flex>
        {renderSteps()}
        <Flex
          borderTop="1px solid #F2F2F2"
          pt="16px"
          justify="end"
          gap="10px"
          mt="20px"
          display={activeStep === 6 ? "none" : "flex"}
        >
          <CustomButton
            btnTitle="Back"
            maxW="160px"
            bg="yess.neutral900"
            onClick={() => {
              if (activeStep === 1) {
                navigate("/exhibitors");
              } else {
                setActiveStep(activeStep - 1);
              }
            }}
          />
          <CustomButton
            btnTitle="Next"
            maxW="160px"
            bg="yess.secondary_gold"
            onClick={() => {
              setActiveStep(activeStep + 1);
            }}
          />
        </Flex>
      </Flex>
    </CustomCard>
  );
}

const STEPS = [
  {
    title: "Company Info",
  },
  {
    title: "Personal Info",
  },
  {
    title: "Event Location",
  },
  {
    title: "Documents",
  },
  {
    title: "Send Invite",
  },
];

export default CreateExhibitor;
