import React from "react";

function IconJpg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.4 0H12L19.2 7.2V21.6C19.2 22.2365 18.9471 22.847 18.4971 23.2971C18.047 23.7471 17.4365 24 16.8 24H2.4C1.76348 24 1.15303 23.7471 0.702944 23.2971C0.252856 22.847 0 22.2365 0 21.6V2.4C0 1.76348 0.252856 1.15303 0.702944 0.702944C1.15303 0.252856 1.76348 0 2.4 0ZM4.5612 19.2C5.6256 19.2 6.2868 18.7176 6.2868 17.3352L6.288 14.2692H5.1948V17.3208C5.1948 18.0768 4.908 18.2856 4.4472 18.2856C4.2312 18.2856 4.0368 18.2496 3.8844 18.1992L3.7632 19.0836C3.978 19.1568 4.3092 19.2 4.5612 19.2ZM8.664 17.4084C9.312 17.4084 9.864 17.2488 10.2396 16.8972C10.5264 16.6236 10.686 16.2204 10.686 15.7452C10.686 15.27 10.4772 14.8668 10.1664 14.622C9.8436 14.3628 9.3612 14.2344 8.6856 14.2344C8.1893 14.2266 7.69321 14.26 7.2024 14.334V19.1196H8.2896V17.3856C8.3904 17.4 8.52 17.4084 8.664 17.4084ZM13.818 19.1712C14.4588 19.1712 15.1008 19.0128 15.4368 18.8976V16.3416H13.632V17.1972H14.3808V18.2124C14.2932 18.2568 14.0928 18.2856 13.8396 18.2856C12.942 18.2856 12.3144 17.6952 12.3144 16.6956C12.3144 15.6444 13.0068 15.126 13.92 15.126C14.4528 15.126 14.7828 15.2196 15.0492 15.3348L15.2784 14.4576C15.042 14.3436 14.5584 14.22 13.9332 14.22C12.342 14.22 11.1708 15.1404 11.1624 16.7532C11.1564 17.466 11.4012 18.0984 11.8464 18.516C12.2928 18.948 12.9336 19.1712 13.818 19.1712ZM10.8 8.4H12H16.8L10.8 2.4V8.4ZM8.28955 15.1056C8.36755 15.084 8.51875 15.0624 8.74195 15.0624C9.29035 15.0624 9.59875 15.3276 9.59875 15.774C9.59875 16.2696 9.23875 16.5648 8.65795 16.5648C8.49835 16.5648 8.38195 16.5588 8.28955 16.5372V15.1056Z"
        fill="#387DF5"
      />
    </svg>
  );
}

export default IconJpg;
