import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Divider, Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import RevenueTrackingChart from "../../components/charts/RevenueTrackingChart";

function RevenueTracking() {
  return (
    <CustomCard maxW="636px" w="100%">
      <Flex justify="space-between" gap="24px" flexDir="column">
        <Flex flexDir="column">
          <Flex align="center" gap="16px">
            <CustomText
              text="Revenue Tracking"
              color="yess.base_black"
              wrap="nowrap"
            />
            <Divider bg="#F2F2F2" mt="5px" />
          </Flex>
          <Flex justify="space-between" mt="16px">
            <Flex
              justify="space-between"
              minH="250px"
              flexDir="column"
              maxW="175px"
            >
              <Flex gap="6px" flexDir="column">
                <CustomText text="Total" size="17px" />
                <CustomText
                  text="$37 Millions"
                  size="32px"
                  color="yess.neutral1000"
                />
              </Flex>
            </Flex>
            <RevenueTrackingChart />
          </Flex>
        </Flex>
        <Flex
          pt="16px"
          borderTop="1px solid"
          borderColor="yess.secondary_light_gray"
          justify="space-between"
          align="center"
        >
          <Flex flexDir="column" align="center" gap="8px">
            <CustomText text="Logistics" size="12px" color="yess.neutral500" />
            <Flex gap="6px" align="center">
              <Box height="12px" width="12px" bg="yess.secondary_gold" />
              <CustomText
                text="$13 Millions"
                size="14px"
                color="yess.neutral1000"
              />
            </Flex>
          </Flex>
          <Flex flexDir="column" align="center" gap="8px">
            <CustomText
              text="Marketing and Advertising"
              size="12px"
              color="yess.neutral500"
            />
            <Flex gap="6px" align="center">
              <Box height="12px" width="12px" bg="#387DF5" />
              <CustomText
                text="$20 Millions"
                size="14px"
                color="yess.neutral1000"
              />
            </Flex>
          </Flex>
          <Flex flexDir="column" align="center" gap="8px">
            <CustomText
              text="Sponsorship and Partnerships"
              size="12px"
              color="yess.neutral500"
            />
            <Flex gap="6px" align="center">
              <Box height="12px" width="12px" bg="#2EC19E" />
              <CustomText
                text="$04 Millions"
                size="14px"
                color="yess.neutral1000"
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </CustomCard>
  );
}

export default RevenueTracking;
