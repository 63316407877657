import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Divider, Flex, Image } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import HeatMap from "../../assets/images/heatmap.svg";

function VisitroHeatMap() {
  return (
    <CustomCard minH="280px" w="100%">
      <Flex flexDir="column" justify="space-between" minH="100%" gap="24px">
        <Flex align="center" gap="16px">
          <CustomText
            text="Visitor Flow Heatmap"
            color="yess.base_black"
            wrap="nowrap"
          />
          <Divider bg="#F2F2F2" mt="6px" />
          <Flex gap="8px" align="center">
            <Box bg="#09E024" minH="10px" minW="10px" />
            <CustomText
              text="Less Dense"
              size="10px"
              color="yess.neutral800"
              wrap="nowrap"
            />
          </Flex>
          <Flex gap="8px" align="center">
            <Box bg="#FF8501" minH="10px" minW="10px" />
            <CustomText
              text="Medium"
              size="10px"
              color="yess.neutral800"
              wrap="nowrap"
            />
          </Flex>
          <Flex gap="8px" align="center">
            <Box bg="#FE120E" minH="10px" minW="10px" />
            <CustomText
              text="Too Much Dense"
              size="10px"
              color="yess.neutral800"
              wrap="nowrap"
            />
          </Flex>
        </Flex>
        <Flex flexDir="column" gap="6pxs">
          <Flex
            justify="space-around"
            align="center"
            pb="6px"
            borderBottom="1px solid #FAFAFA"
          >
            <Flex flexDir="column" gap="4px" align="center">
              <CustomText text="Entries" size="10px" color="yess.neutral500" />
              <CustomText text="29%" size="12px" color="yess.neutral800" />
            </Flex>
            <Flex flexDir="column" gap="4px" align="center">
              <CustomText
                text="Inside Hall"
                size="10px"
                color="yess.neutral500"
              />
              <CustomText text="46%" size="12px" color="yess.neutral800" />
            </Flex>
            <Flex flexDir="column" gap="4px" align="center">
              <CustomText text="Exit" size="10px" color="yess.neutral500" />
              <CustomText text="35%" size="12px" color="yess.neutral800" />
            </Flex>
          </Flex>
          <Image src={HeatMap} alt="heatmap" draggable={false} />
        </Flex>
      </Flex>
    </CustomCard>
  );
}

export default VisitroHeatMap;
