import React from "react";
import Chart from "react-apexcharts";

const PieChart = () => {
  const series = [1256, 232, 70, 32, 68, 62]; // Data values for Media, Contractor, University, Defence
  const labels = [
    "Exhibitors",
    "Organizations",
    "Media",
    "Contractor",
    "University",
    "Defence",
  ]; // Labels for the chart

  const options: any = {
    chart: {
      type: "pie",
    },
    labels: labels,
    colors: ["#D79A2B", "#387DF5", "#001C70", "#0046BC", "#69A0FF", "#9ABFFF"],
    legend: {
      show: false,
    },
  };

  return (
    <div>
      <Chart options={options} series={series} type="pie" width="300" />
    </div>
  );
};

export default PieChart;
