import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Divider, Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import SunburstChart from "../../components/charts/SubBurstChart";

function ExhibitorsByType() {
  return (
    <CustomCard maxW="532px" w="100%">
      <Flex minH="342px" justify="space-between" flexDir="column" gap="20px">
        <Flex align="center" gap="16px">
          <CustomText
            text="Exhibitors By Type"
            color="yess.base_black"
            wrap="nowrap"
          />
          <Divider bg="#F2F2F2" mt="5px" />
        </Flex>
        <Flex justify="space-between" align="center" maxW="484px" ps="32px">
          <Flex gap="16px" flexDir="column" align="center">
            <SunburstChart />
            <Flex align="center" gap="24px">
              <Flex flexDir="column" gap="8px">
                <CustomText
                  text="Exhibitors"
                  size="12px"
                  color="yess.neutral500"
                />
                <Flex gap="6px" align="center">
                  <Box bg="#D79A2B" height="12px" width="12px" mt="2px" />
                  <CustomText
                    text="1,256"
                    size="14px"
                    color="yess.neutral1000"
                  />
                </Flex>
              </Flex>
              <Flex flexDir="column" gap="8px">
                <CustomText
                  text="Organizations"
                  size="12px"
                  color="yess.neutral500"
                />
                <Flex gap="6px" align="center">
                  <Box bg="#387DF5" height="12px" width="12px" mt="2px" />
                  <CustomText text="232" size="14px" color="yess.neutral1000" />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDir="column" gap="16px">
            <Flex flexDir="column" gap="8px">
              <CustomText text="Media" size="12px" color="yess.neutral500" />
              <Flex gap="6px" align="center">
                <Box bg="#001C70" height="12px" width="12px" mt="2px" />
                <CustomText text="70" size="14px" color="yess.neutral1000" />
              </Flex>
            </Flex>
            <Flex flexDir="column" gap="8px">
              <CustomText
                text="Contractor"
                size="12px"
                color="yess.neutral500"
              />
              <Flex gap="6px" align="center">
                <Box bg="#0046BC" height="12px" width="12px" mt="2px" />
                <CustomText text="32" size="14px" color="yess.neutral1000" />
              </Flex>
            </Flex>
            <Flex flexDir="column" gap="8px">
              <CustomText
                text="University"
                size="12px"
                color="yess.neutral500"
              />
              <Flex gap="6px" align="center">
                <Box bg="#69A0FF" height="12px" width="12px" mt="2px" />
                <CustomText text="68" size="14px" color="yess.neutral1000" />
              </Flex>
            </Flex>
            <Flex flexDir="column" gap="8px">
              <CustomText text="Defence" size="12px" color="yess.neutral500" />
              <Flex gap="6px" align="center">
                <Box bg="#9ABFFF" height="12px" width="12px" mt="2px" />
                <CustomText text="62" size="14px" color="yess.neutral1000" />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </CustomCard>
  );
}

export default ExhibitorsByType;
