import React from "react";

function MapIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.3802 3.06691C1.91353 0.760247 5.38687 0.760247 5.91353 3.06691C6.22687 4.42025 5.36687 5.56691 4.6202 6.28025C4.07353 6.80025 3.21353 6.79358 2.66687 6.28025C1.92687 5.56691 1.06687 4.42025 1.3802 3.06691Z"
        stroke="#BAB8B8"
        stroke-width="1.5"
      />
      <path
        d="M10.0467 11.0669C10.58 8.76025 14.0733 8.76025 14.6067 11.0669C14.92 12.4202 14.06 13.5669 13.3067 14.2802C12.76 14.8002 11.8933 14.7936 11.3467 14.2802C10.5933 13.5669 9.73333 12.4202 10.0467 11.0669Z"
        stroke="#BAB8B8"
        stroke-width="1.5"
      />
      <path
        d="M8.00013 3.33301H9.78679C11.0201 3.33301 11.5935 4.85967 10.6668 5.67301L5.34013 10.333C4.41346 11.1397 4.98679 12.6663 6.21346 12.6663H8.00013"
        stroke="#BAB8B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.65748 3.66634H3.66518"
        stroke="#BAB8B8"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.324 11.6663H12.3317"
        stroke="#BAB8B8"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default MapIcon;
