import { Flex } from "@chakra-ui/react";
import React from "react";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import FormInput from "../../components/form/FormInput";
import IconChevDown from "../../svgs/IconChevDown";
import CustomPhoneInput from "../../components/form/PhoneInput";
import IconCalendar from "../../svgs/IconCalendar";

function PersonalInfo() {
  return (
    <Flex
      flexDir="column"
      gap="22px"
      maxW="666px"
      w="100%"
      mx="auto"
      minH="521px"
    >
      <CustomHeading text="Personal Info" family="aeonik-bold" align="center" />
      <CustomText
        text="Enter Personal Info of the exhibitor"
        align="center"
        color="yess.neutral600"
      />
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="First Name" size="12px" />
          <FormInput ph="Enter First Name" maxW="322px" />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Last Name" size="12px" />
          <FormInput ph="Enter Last Name" maxW="322px" />
        </Flex>
      </Flex>
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Email" size="12px" />
          <FormInput ph="Enter Email" maxW="322px" />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="DOB" size="12px" />
          <FormInput ph="Enter DOB" maxW="322px" rightIco={<IconCalendar />} />
        </Flex>
      </Flex>
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Nationality" size="12px" />
          <FormInput
            ph="Select Nationality"
            maxW="322px"
            rightIco={<IconChevDown />}
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Phone Number" size="12px" />
          <CustomPhoneInput />
        </Flex>
      </Flex>
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Country" size="12px" />
          <FormInput
            ph="Select Country"
            maxW="322px"
            rightIco={<IconChevDown />}
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="City" size="12px" />
          <FormInput
            ph="Select City"
            maxW="322px"
            rightIco={<IconChevDown />}
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Zip Code" size="12px" />
          <FormInput ph="Enter Zip Code" maxW="322px" />
        </Flex>
      </Flex>

      <Flex flexDir="column" gap="11px" w="100%">
        <CustomText text="Address" size="12px" />
        <FormInput ph="Enter Address" />
      </Flex>
    </Flex>
  );
}

export default PersonalInfo;
