import { Box, Input } from "@chakra-ui/react";
import React from "react";

function FormInput({ type, rightIco, ph, maxW, h }: any) {
  return (
    <Box pos="relative">
      <Input
        placeholder={ph || ""}
        type={type || "text"}
        fontSize="12px"
        px="24px"
        h={h || "38px"}
        w="100%"
        maxW={maxW || "100%"}
        fontWeight="400"
        color="yess.base_black"
        bg="#F2F2F2"
        _placeholder={{
          color: "yess.neutral600",
        }}
        border="none"
        borderRadius="8px"
        _focus={{
          border: "none",
        }}
        _focusVisible={{
          border: "none",
        }}
        _hover={{
          border: "none",
        }}
        className="aeonik-regular"
      />
      {rightIco && (
        <Box
          pos="absolute"
          top="0"
          right="0"
          mt={h ? "18px" : "12px"}
          mr="24px"
        >
          {rightIco}
        </Box>
      )}
    </Box>
  );
}

export default FormInput;
